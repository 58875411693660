import React from 'react';
import { Container } from '@mui/material';
import { TbLoader3 } from 'react-icons/tb';

function LoadingScreen() {
  return (
    <Container maxWidth="lg" sx={{ pt: 6, pb: 9, minHeight: 'calc(100vh - 150px)' }} className="loading-screen">
      <div>
        <TbLoader3 className="loader" />
      </div>
      Aan het laden...
    </Container>
  );
}

export default LoadingScreen;
