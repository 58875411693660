import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, Button, Box,
} from '@mui/material';
import Swal2 from 'sweetalert2';
import {
  TbTag, TbTrash,
} from 'react-icons/tb';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { TOPICS_URL } from '../../../../Constants/URLS';
import { containerStyles } from '../../../../Components/TableStyles/TableStyles';
import ModerationComponent from '../../../../Components/ModerationComponent';

function TopicEditView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const maxChars = 255;
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  // Fetch the topic by ID when the component mounts
  useEffect(() => {
    const fetchTopic = async () => {
      try {
        const response = await api.get(`${TOPICS_URL}${id}`);
        setName(response.data.name);
        setDescription(response.data.description);
      } catch (error) {
        Swal2.fire({
          title: 'Fout',
          text: `Kan onderwerp niet laden: ${error.response ? error.response.data.message : error.message}`,
          icon: 'error',
        });
      }
    };

    fetchTopic();
  }, [id]);

  const handleNameChange = (e) => {
    if (e.target.value.length <= maxChars) {
      setName(e.target.value);
    }
  };

  const updateTopic = async () => {
    try {
      const response = await api.patch(`${TOPICS_URL}${id}/`, { name, description });

      if (response.status === 200) {
        Swal2.fire({
          title: 'Succesvol',
          text: 'Het onderwerp is bijgewerkt.',
          icon: 'success',
        });
      }
    } catch (error) {
      Swal2.fire({
        title: 'Fout',
        text: `Kan het onderwerp niet updaten: ${error.response ? error.response.data.message : error.message}`,
        icon: 'error',
      });
    }
  };

  const deleteTopic = async () => {
    Swal2.fire({
      title: 'Weet je het zeker?',
      text: 'Dit kan niet ongedaan worden gemaakt!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, verwijder het!',
      cancelButtonText: 'Annuleren',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.del(`${TOPICS_URL}${id}/`);
          Swal2.fire(
            'Verwijderd!',
            'Het onderwerp is verwijderd.',
            'success',
          );
          navigate('/backoffice/topics');
        } catch (error) {
          Swal2.fire({
            title: 'Fout',
            text: `Kan het onderwerp niet verwijderen: ${error.response ? error.response.data.message : error.message}`,
            icon: 'error',
          });
        }
      }
    });
  };

  useEffect(() => {
    setIsFormValid(name.trim().length > 0 && description.trim().length > 0);
  }, [name, description]);

  return (
    <Container maxWidth="lg" sx={containerStyles}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className="heading" style={{ marginBottom: 0 }}>
          <TbTag />
          <h1>Onderwerp bewerken</h1>
        </div>
        <Button
          className="btn btn-red"
          startIcon={<TbTrash />}
          onClick={deleteTopic}
        >
          Verwijder
        </Button>
      </Box>
      <div className="chat-backoffice-view">
        <h4>Naam</h4>
        <input
          value={name}
          onChange={handleNameChange}
        />
        <span className={`helper ${name.length === maxChars ? 'error' : ''}`}>
          {`${name.length}/${maxChars}`}
        </span>
        <h4>Beschrijving</h4>
        <input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <ModerationComponent modelType="topic" userContext={userContext} />
        <Button
          startIcon={<IconDeviceFloppy />}
          className="btn btn-green"
          onClick={updateTopic}
          disabled={!isFormValid}
          sx={{ mt: 3 }}
        >
          Bijwerken
        </Button>
      </div>
    </Container>
  );
}

export default TopicEditView;
