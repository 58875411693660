import React from 'react';
import PropTypes from 'prop-types';
import {
  VictoryLine, VictoryChart, VictoryAxis, VictoryTooltip, VictoryScatter,
} from 'victory';

function LineGraph({
  data, yAxisKey, yAxisDecimalPlaces, lineColor, startYAxisAtZero, yAxisLabel, period,
}) {
  // Get the function to span more than a month
  const minDate = new Date(Math.min(...data.map((d) => new Date(d.date))));
  const maxDate = new Date(Math.max(...data.map((d) => new Date(d.date))));
  const spansMoreThanOneMonth = maxDate.getMonth() !== minDate.getMonth()
    || maxDate.getFullYear() !== minDate.getFullYear();

  // Check if data is empty
  const isDataEmpty = data.length === 0;

  // Check if there is only one data point
  const isSingleDataPoint = data.length === 1;

  // Default formatting functions
  const formatYAxis = (tick) => (typeof tick === 'number' ? tick.toFixed(yAxisDecimalPlaces) : '');
  // Updated formatting functions
  const formatXAxis = (date) => {
    const dateObject = new Date(date);
    if (period === 'all') {
      return `${dateObject.toLocaleString('nl', { month: 'short' })} '${dateObject.getFullYear().toString().slice(-2)}`;
    }
    if (period === 'yearly') {
      return dateObject.toLocaleString('nl', { month: 'long' });
    }
    const day = dateObject.getDate();
    const month = spansMoreThanOneMonth ? `${dateObject.toLocaleString('nl', { month: 'short' })} ` : '';
    return `${month}${day}`;
  };

  // Conditional style for X-axis labels
  const xAxisTickStyle = {
    tickLabels: {
      // Rotate labels for 'yearly' and 'all' periods
      angle: (period === 'yearly' || period === 'all') ? -45 : 0,
      padding: (period === 'yearly' || period === 'all') ? 20 : 5,
    },
    ticks: { stroke: 'black', size: 3 },
  };

  // Calculate domain and tick values
  const maxYValue = isDataEmpty ? 1 : Math.max(...data.map((d) => d[yAxisKey])) + 1;
  const stepSize = Math.max(1, Math.ceil(maxYValue / 10));
  const tickValues = Array.from({
    length: Math.ceil(maxYValue / stepSize),
  }, (_, i) => i * stepSize);

  const domain = startYAxisAtZero || isDataEmpty ? { y: [0, maxYValue] } : {};

  // Render a message when there is no data
  if (isDataEmpty) {
    return (
      <div
        style={{
          height: 318, display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <p>Geen gegevens voor deze periode</p>
      </div>
    );
  }

  return (
    <VictoryChart
      padding={{
        top: 50, bottom: 50, left: 50, right: 50,
      }}
      domain={domain}
    >
      <VictoryAxis
        label={period === 'monthly' ? 'Dag' : ''}
        tickFormat={formatXAxis}
        style={xAxisTickStyle}
        fixLabelOverlap
      />
      <VictoryAxis
        dependentAxis
        label={yAxisLabel}
        tickFormat={formatYAxis}
        tickValues={tickValues}
        style={{
          axisLabel: {
            padding: 35,
          },
        }}
      />
      <VictoryLine
        data={data}
        x="date"
        y={yAxisKey}
        style={{ data: { stroke: lineColor } }}
        labelComponent={<VictoryTooltip />}
      />
      {/* Render VictoryScatter if there is only one data point */}
      {isSingleDataPoint && (
        <VictoryScatter
          data={data}
          x="date"
          y={yAxisKey}
          size={5}
          style={{ data: { fill: lineColor } }}
        />
      )}
    </VictoryChart>
  );
}

LineGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
    [PropTypes.string]: PropTypes.number,
  })).isRequired,
  yAxisKey: PropTypes.string.isRequired,
  yAxisDecimalPlaces: PropTypes.number,
  lineColor: PropTypes.string,
  startYAxisAtZero: PropTypes.bool,
  yAxisLabel: PropTypes.string,
  period: PropTypes.string,
};

LineGraph.defaultProps = {
  yAxisDecimalPlaces: 0,
  lineColor: '#4572a7',
  startYAxisAtZero: false,
  yAxisLabel: '',
  period: 'monthly',
};

export default LineGraph;
