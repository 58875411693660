import React, { createContext, useState } from 'react';

import PropTypes from 'prop-types';

export const CaseBeheerContext = createContext();

function CaseBeheerProvider({ children }) {
  const [selectedSectorId, setSelectedSectorId] = useState(null);
  const [selectedSubsectorId, setSelectedSubsectorId] = useState(null);

  return (
    <CaseBeheerContext.Provider value={{
      selectedSectorId,
      setSelectedSectorId,
      selectedSubsectorId,
      setSelectedSubsectorId,
    }}
    >
      {children}
    </CaseBeheerContext.Provider>
  );
}

CaseBeheerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CaseBeheerProvider;
