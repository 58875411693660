import { USER_LOG_URL } from '../../Constants/URLS';

export default function logUserAction(userId, action, api) {
  const logData = {
    user: userId,
    action,
  };

  api.post(USER_LOG_URL, logData)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error logging user action:', error);
    });
}
