import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

function StatusChip({ chipStatus }) {
  switch (chipStatus) {
    case 'Live':
      return <Chip className="status-chip" label="Live" style={{ backgroundColor: '#BAD60A' }} size="small" />;
    case 'Offline':
      return <Chip className="status-chip" label="Offline" style={{ backgroundColor: '#F45051' }} size="small" />;
    case 'Eerste check':
      return <Chip className="status-chip" label="Eerste check" style={{ backgroundColor: '#FFA500' }} size="small" />; // Orange
    case 'Tweede check':
      return <Chip className="status-chip" label="Tweede check" style={{ backgroundColor: '#1E90FF' }} size="small" />; // Dodger Blue
    default:
      return <Chip className="status-chip" label={chipStatus} size="small" />;
  }
}

StatusChip.propTypes = {
  chipStatus: PropTypes.string.isRequired,
};

export default StatusChip;
