import React, {
  useState, useContext, useEffect,
} from 'react';
import {
  Container, Button, Alert, Tooltip,
} from '@mui/material';
import { AsyncPaginate } from 'react-select-async-paginate';
import Swal2 from 'sweetalert2';
import {
  TbPlus, TbLoader3, TbBook,
} from 'react-icons/tb';
import { useDropzone } from 'react-dropzone';
import { IconTrash } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { SOURCES_URL, TOPICS_LIGHT_URL } from '../../../../Constants/URLS';
import { containerStyles } from '../../../../Components/TableStyles/TableStyles';
import sourceIcon from '../../../../Utils/SourceUtils';
import topicsStyles from '../../topicStyles';

function SourceAddView() {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const maxChars = 255;
  const [title, setTitle] = useState('');
  const [additionalText, setAdditionalText] = useState('');
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [webLink, setWebLink] = useState('');
  const [webLinkError, setWebLinkError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const isValidURL = (string) => {
    const res = string.match(/(https?:\/\/[^\s]+)/g);
    return (res !== null);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'text/plain': ['.txt'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    maxFiles: 1,
  });

  const handleWebLinkChange = (e) => {
    const url = e.target.value;
    setWebLink(url);
    if (url && !isValidURL(url)) {
      setWebLinkError('Ongeldige URL');
    } else {
      setWebLinkError('');
    }
  };

  const handleTitleChange = (e) => {
    if (e.target.value.length <= maxChars) {
      setTitle(e.target.value);
    }
  };

  const handleSelectTopics = (selectedOptions) => {
    setSelectedTopics(selectedOptions || []);
  };

  const loadTopics = async (search) => {
    const response = await api.get(`${TOPICS_LIGHT_URL}?search=${search}`);

    const loadedTopics = {
      options: response.data.map((topic) => ({
        value: topic.id,
        label: topic.name,
      })),
      hasMore: false,
    };

    return loadedTopics;
  };

  const submitSource = async () => {
    setIsLoading(true);
    try {
      // Create a FormData object to handle the file upload and other form data
      const formData = new FormData();
      formData.append('title', title);
      formData.append('additional_text', additionalText);

      if (webLink) {
        formData.append('web_link', webLink);
      }

      if (acceptedFiles.length > 0) {
        // Only upload the first file
        formData.append('source_file', acceptedFiles[0]);
      }

      // First request to create the source
      const response = await api.post(SOURCES_URL, formData);

      if (response.status === 201) {
        const sourceId = response.data.id;

        // Second request to update the topics
        // TODO: Make this a single request. Including the topics in form data messes with
        // the serialisation of PrimaryKeyRelatedField and it's surprisingly difficult
        // to make work :(
        const topicsData = {
          topics: selectedTopics.map((topic) => topic.value),
        };

        await api.patch(`${SOURCES_URL}${sourceId}/`, topicsData);

        // Show success alert
        Swal2.fire({
          title: 'Succesvol',
          text: 'Het bron is toegevoegd.',
          icon: 'success',
        }).then(() => {
          navigate(`/sources/edit/${sourceId}`);
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      // Show error alert
      Swal2.fire({
        title: 'Fout',
        text: `Er is een fout opgetreden bij het toevoegen van het bron: ${error.response ? error.response.data.message : error.message}`,
        icon: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Update form validity
  useEffect(() => {
    const isTitleValid = title.trim().length > 0;
    const isAdditionalTextValid = additionalText.trim().length > 0;
    const isSourceFileValid = acceptedFiles.length > 0;
    const isWebLinkValid = !webLink || (webLink && !webLinkError);
    const isValid = (isTitleValid && isAdditionalTextValid)
      || isSourceFileValid
      || (isAdditionalTextValid && !isSourceFileValid);
    setIsFormValid(isValid && isWebLinkValid);
  }, [title, additionalText, acceptedFiles, webLink, webLinkError]);

  return (
    <Container maxWidth="lg" sx={containerStyles}>
      <div className="heading">
        <TbBook />
        <h1>Voeg een bron toe</h1>
      </div>
      <div className="chat-backoffice-view">
        <h4>Titel</h4>
        <input
          value={title}
          onChange={handleTitleChange}
          placeholder="Voer hier een titel in..."
        />
        <span className={`helper ${title.length === maxChars ? 'error' : ''}`}>
          {`${title.length}/${maxChars}`}
        </span>
        <h4>Bronbestand</h4>
        {acceptedFiles.length === 0 && (!webLink || webLinkError) && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Je moet ofwel een bronbestand uploaden of een web link invoeren.
          </Alert>
        )}
        {/* eslint-disable-next-line */}
        <div {...getRootProps({className: 'dropzone'})}>
          {/* eslint-disable-next-line */}
          <input {...getInputProps()} />
          {acceptedFiles.length > 0 ? (
            <Tooltip title="Verwijder bestand" arrow placement="top">
              <div className="dropzone-file">
                <img src={sourceIcon({ source_file: acceptedFiles[0].path })} alt="source-icon" />
                {acceptedFiles[0].path}
                <div className="overlay">
                  <IconTrash />
                </div>
              </div>
            </Tooltip>
          ) : (
            <p>Drag & drop het bestand hierheen of klik om een bestand te selecteren.</p>
          )}
        </div>
        <h4>Aanvullende tekst</h4>
        <textarea
          rows={4}
          value={additionalText}
          onChange={(e) => setAdditionalText(e.target.value)}
          placeholder="Typ hier je aanvullende tekst..."
        />
        <h4>Onderwerpen</h4>
        <AsyncPaginate
          loadOptions={loadTopics}
          onChange={handleSelectTopics}
          isSearchable
          isClearable
          additional={{}}
          isMulti
          debounceTimeout={300}
          placeholder="Selecteer onderwerpen..."
          styles={topicsStyles}
        />
        <h4>Web Link</h4>
        <input
          value={webLink}
          onChange={handleWebLinkChange}
          placeholder="Voer hier een web link in..."
        />
        {!!webLinkError && (
          <span className="helper error">{webLinkError}</span>
        )}
        <Button
          startIcon={isLoading ? <TbLoader3 size={20} className="loader" /> : <TbPlus />}
          className="btn btn-green"
          onClick={submitSource}
          disabled={!isFormValid || isLoading}
          sx={{ mt: 3 }}
        >
          Versturen
        </Button>
      </div>
    </Container>
  );
}

export default SourceAddView;
