export function isProduction() {
  return process.env.REACT_APP_ENVIRONMENT === 'prod';
}

export function isStaging() {
  return process.env.REACT_APP_ENVIRONMENT === 'dev';
}

export function isLocal() {
  return process.env.REACT_APP_ENVIRONMENT === 'local';
}
