import React, { useState, useContext, useEffect } from 'react';
import {
  Container, Button,
} from '@mui/material';
import Swal2 from 'sweetalert2';
import { TbTag, TbPlus } from 'react-icons/tb';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { TOPICS_URL } from '../../../../Constants/URLS';
import { containerStyles } from '../../../../Components/TableStyles/TableStyles';

function TopicAddView() {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const maxChars = 255;
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const handleNameChange = (e) => {
    if (e.target.value.length <= maxChars) {
      setName(e.target.value);
    }
  };

  const submitTopic = async () => {
    try {
      const response = await api.post(TOPICS_URL, { name, description });

      if (response.status === 201) {
        // Show success alert
        Swal2.fire({
          title: 'Succesvol',
          text: 'Het onderwerp is toegevoegd.',
          icon: 'success',
        });

        // Clear the form fields
        setName('');
        setDescription('');
      }
    } catch (error) {
      // Show error alert
      Swal2.fire({
        title: 'Fout',
        text: `Er is een fout opgetreden bij het toevoegen van het onderwerp: ${error.response ? error.response.data.message : error.message}`,
        icon: 'error',
      });
    }
  };

  // Update form validity
  useEffect(() => {
    setIsFormValid(name.trim().length > 0 && description.trim().length > 0);
  }, [name, description]);

  return (
    <Container maxWidth="lg" sx={containerStyles}>
      <div className="heading">
        <TbTag />
        <h1>Voeg een onderwerp toe</h1>
      </div>
      <div className="chat-backoffice-view">
        <h4>Naam</h4>
        <input
          value={name}
          onChange={handleNameChange}
        />
        <span className={`helper ${name.length === maxChars ? 'error' : ''}`}>
          {`${name.length}/${maxChars}`}
        </span>
        <h4>Beschrijving</h4>
        <input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button
          startIcon={<TbPlus />}
          className="btn btn-green"
          onClick={submitTopic}
          disabled={!isFormValid}
          sx={{ mt: 3 }}
        >
          Versturen
        </Button>
      </div>
    </Container>
  );
}

export default TopicAddView;
