import getApiHost from '../Utils/UrlUtils';

const API_URL = `${getApiHost()}/api/v1`;

// eslint-disable-next-line import/prefer-default-export
export const MEDIA_URL = `${process.env.REACT_APP_MEDIA_URL}`;
export const PRIVACY_POLICY_URL = `${API_URL}/privacy-policy/`;
export const TERMS_OF_USE_URL = `${API_URL}/terms-of-use/`;
export const LOGIN_URL = `${API_URL}/auth/token/`;
export const REQUEST_URL = `${API_URL}/users/me`;
export const REFRESH_URL = `${API_URL}/auth/token/refresh/`;
export const SIGNUP_URL = `${API_URL}/dj-rest-auth/registration/`;
export const JOB_ROLES_URL = `${API_URL}/job-roles/`;
export const PERMISSION_ROLES = `${API_URL}/permission-roles/`;
export const CLIENTS_URL = `${API_URL}/clients/`;
export const PROFESSIONS_URL = `${API_URL}/professions/`;
export const PROFESSIONS_NO_EMBEDDING_URL = `${API_URL}/professions-no-embedding/`;
export const INVITATION_URL = `${API_URL}/invitations/`;
export const VALIDATE_ACCESS_CODE = `${INVITATION_URL}validate_code/`;
export const MATCHES_URL = `${API_URL}/matches`;
export const PDF_DOWNLOADS_URL = `${API_URL}/pdf-downloads/`;
export const USER_DETAILS_URL = `${API_URL}/dj-rest-auth/user/`;
export const EMAIL_CONFIRM_URL = `${API_URL}/dj-rest-auth/registration/verify-email/`;
export const RESET_PASSWORD_URL = `${API_URL}/dj-rest-auth/password/reset/`;
export const PASSWORD_RESET_CONFIRM_URL = `${RESET_PASSWORD_URL}confirm/`;
export const CHANGE_PASSWORD_URL = `${API_URL}/dj-rest-auth/password/change/`;
export const ACTIVE_USERS_MATCHES_URL = `${API_URL}/active-users-matches`;
export const TOTAL_MATCHES_URL = `${API_URL}/total-matches`;
export const AVERAGE_CLIENT_AGE_URL = `${API_URL}/average-client-age`;
export const BADLY_MATCHED_JOBS = `${API_URL}/matches-job-roles`;
export const PROFESSION_POPULARITY_URL = `${API_URL}/profession-popularity`;
export const CASE_STUDY_POPULARITY_URL = `${API_URL}/case-study-popularity`;
export const COUNT_PDF_DOWNLOADS_URL = `${API_URL}/count-pdf-downloads`;
export const USER_MATCHES_URL = `${API_URL}/user-matches`;
export const START_CONVERSATION_URL = `${API_URL}/conversations/start/`;
export const CHATBOT_RESPONSES_URL = `${API_URL}/chatbot-responses/`;
export const CHATBOT_RESPONSES_GENERATE_URL = `${CHATBOT_RESPONSES_URL}generate_response/`;
export const USER_RESPONSES_URL = `${API_URL}/user-responses/`;
export const CONVERSATIONS_URL = `${API_URL}/conversations/`;
export const USER_TABLE_URL = `${API_URL}/users-table/`;
export const CASE_STUDY_TABLE_URL = `${API_URL}/case-studies/`;
export const CASE_STUDY_CREATE_URL = `${API_URL}/case-studies/create-in-react/`;
export const USER_URL = `${API_URL}/users/`;
export const USER_LOG_URL = `${API_URL}/user-log/`;
export const SECTOR_URL = `${API_URL}/sectors/`;
export const SUBSECTOR_URL = `${API_URL}/subsectors/`;
export const RISKS_URL = `${API_URL}/risks/`;
export const RISKS_ICONS_URL = `${API_URL}/risk-icons/`;
export const CASE_STUDY_IMAGES_URL = `${API_URL}/case-study-images/`;
export const GENERATE_CASE_STUDY_URL = `${API_URL}/prepublished-case-studies/`;
export const PROFESSION_LEVELS_URL = `${API_URL}/profession-levels/`;
export const GENERAL_ILLNESS_URL = `${API_URL}/general-illnesses/`;
export const TASK_CHECKING_URL = `${API_URL}/task-status/`;
export const FEEDBACK_URL = `${API_URL}/feedback/`;
export const FEEDBACK_TABLE_URL = `${API_URL}/feedback-table/`;
export const SOURCES_TABLE_URL = `${API_URL}/sources-table/`;
export const TOPICS_TABLE_URL = `${API_URL}/topics-table/`;
export const FAQ_TABLE_URL = `${API_URL}/faq-table/`;
export const FREQUENTLY_ASKED_QUESTIONS_URL = `${API_URL}/faqs/`;
export const TOPICS_URL = `${API_URL}/topics/`;
export const TOPICS_LIGHT_URL = `${API_URL}/topics-light/`;
export const SOURCES_URL = `${API_URL}/sources/`;
export const MODERATION_URL = `${API_URL}/moderations/`;
export const CONVERSATION_TABLE_VIEW = `${API_URL}/conversations-table/`;
