import React, { useState, useEffect, useContext } from 'react';
import {
  Container, Grid, List, ListItem,
  ListItemButton, Button, Modal, CircularProgress, IconButton, Collapse, Tooltip,
  Alert, Pagination,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  TbAlertTriangle,
  TbArrowRight, TbCheck,
  TbDeviceFloppy, TbFaceMask,
  TbHelpHexagon, TbPencil, TbPlus, TbTrash, TbX,
} from 'react-icons/tb';
import Swal from 'sweetalert2';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import LoadingScreen from '../LoadingScreen';
import AccessDeniedMessage from '../../Components/AccessDeniedMessage';
import {
  SECTOR_URL, SUBSECTOR_URL, MEDIA_URL, GENERAL_ILLNESS_URL,
  PROFESSIONS_URL, CASE_STUDY_TABLE_URL, RISKS_URL, RISKS_ICONS_URL, PROFESSIONS_NO_EMBEDDING_URL,
} from '../../Constants/URLS';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import { CaseBeheerContext } from '../../Providers/CaseBeheerProvider/CaseBeheerProvider';

function CaseContentDashboard() {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const allowedRoles = ['Staff', 'Admin'];
  const hasAccess = allowedRoles.includes(userContext.user.role);

  const [illnesses, setIllnesses] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentIllness, setCurrentIllness] = useState({ id: null, name: '', percentage: '' });

  const [sectors, setSectors] = useState(null);
  const [subsectors, setSubsectors] = useState(null);
  const [professions, setProfessions] = useState(null);
  const [caseStudies, setCaseStudies] = useState(null);
  const [selectedProfessionId, setSelectedProfessionId] = useState(null);

  const {
    selectedSectorId, setSelectedSectorId, selectedSubsectorId, setSelectedSubsectorId,
  } = useContext(CaseBeheerContext);

  const [newSectorName, setNewSectorName] = useState('');
  const [newSubsectorName, setNewSubsectorName] = useState('');
  const [newProfessionName, setNewProfessionName] = useState('');

  const [availableIcons, setAvailableIcons] = useState([]);

  const [editingItemId, setEditingItemId] = useState(null);
  const [editingItemType, setEditingItemType] = useState(null);
  const [editingItemName, setEditingItemName] = useState('');
  const [editingItemNickname, setEditingItemNickname] = useState('');
  const [editingModalOpen, setEditingModalOpen] = useState(false);

  const [professionsLoading, setProfessionsLoading] = useState(false);
  const [professionsPage, setProfessionsPage] = useState(1);
  const [professionsPages, setProfessionsPages] = useState(1);

  const [casesLoading, setCasesLoading] = useState(false);
  const [casesPage, setCasesPage] = useState(1);
  const [casesPages, setCasesPages] = useState(1);

  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [alertOpen, setAlertOpen] = useState(true);

  const [onlyWithoutCases, setOnlyWithoutCases] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRisk, setCurrentRisk] = useState(null);
  const [hasRiskChanges, setHasRiskChanges] = useState(false);

  const startEditing = (id, name, type) => {
    switch (type) {
      case 'profession':
        setEditingItemName(professions.find((profession) => profession.id === id).job_role);
        setEditingItemNickname(professions.find((profession) => profession.id === id).nickname);
        break;
      case 'sector':
        setEditingItemName(sectors.find((sector) => sector.id === id).name);
        setEditingItemNickname(sectors.find((sector) => sector.id === id).nickname);
        break;
      case 'subsector':
        setEditingItemName(subsectors.find((subsector) => subsector.id === id).name);
        setEditingItemNickname(subsectors.find((subsector) => subsector.id === id).nickname);
        break;
      default:
        break;
    }
    setEditingItemId(id);
    setEditingItemType(type);
    setEditingModalOpen(true);
  };

  useEffect(() => {
    if (selectedProfessionId) {
      const profession = professions?.find((prof) => prof.id === selectedProfessionId);
      if (!profession) return;
      if (selectedSubsectorId !== profession.subsector) {
        setSelectedSubsectorId(profession.subsector);
      }
    }
  }, [selectedProfessionId, professions]);

  useEffect(() => {
    if (selectedSubsectorId) {
      const subsector = subsectors?.find((sub) => sub.id === selectedSubsectorId);
      if (!subsector) return;
      if (selectedSectorId !== subsector.sector) {
        setSelectedSectorId(subsector.sector);
      }
    }
  }, [selectedSubsectorId, subsectors]);

  const handleDeleteItem = (id, itemType) => {
    let url;
    let confirmText;

    switch (itemType) {
      case 'sector':
        url = `${SECTOR_URL}${id}/`;
        confirmText = 'Weet je zeker dat je deze sector wilt verwijderen?';
        break;
      case 'subsector':
        url = `${SUBSECTOR_URL}${id}/`;
        confirmText = 'Weet je zeker dat je deze subsector wilt verwijderen?';
        break;
      case 'profession':
        url = `${PROFESSIONS_URL}${id}/`;
        confirmText = 'Weet je zeker dat je dit beroep wilt verwijderen?';
        break;
      default:
        return;
    }

    Swal.fire({
      title: confirmText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja, verwijder',
      cancelButtonText: 'Nee, annuleer',
    }).then((result) => {
      if (result.isConfirmed) {
        api.del(url)
          .then(() => {
            switch (itemType) {
              case 'sector':
                setSectors(sectors.filter((sector) => sector.id !== id));
                break;
              case 'subsector':
                setSubsectors(subsectors.filter((subsector) => subsector.id !== id));
                break;
              case 'profession':
                setProfessions(professions.filter((profession) => profession.id !== id));
                break;
              default:
                break;
            }

            Swal.fire(
              'Verwijderd!',
              'Het item is verwijderd.',
              'success',
            );
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            Swal.fire(
              'Fout!',
              'Er is iets fout gegaan bij het verwijderen.',
              'error',
            );
          });
      }
    });
  };

  const handleAddSector = () => {
    Swal.fire({
      title: 'Weet je zeker dat je een nieuwe sector wilt toevoegen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja, voeg toe',
      cancelButtonText: 'Nee, annuleer',
    }).then((result) => {
      if (result.isConfirmed) {
        api.post(SECTOR_URL, { name: newSectorName })
          .then((response) => {
            setSectors([...sectors, response.data]);
            setNewSectorName('');
            Swal.fire(
              'Toegevoegd!',
              'De nieuwe sector is toegevoegd.',
              'success',
            );
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            Swal.fire(
              'Fout!',
              'Er is iets fout gegaan bij het toevoegen van de sector.',
              'error',
            );
          });
      }
    });
  };

  const handleAddSubsector = () => {
    if (!selectedSectorId) {
      Swal.fire('Selecteer eerst een sector.', '', 'info');
      return;
    }

    Swal.fire({
      title: 'Weet je zeker dat je een nieuwe subsector wilt toevoegen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja, voeg toe',
      cancelButtonText: 'Nee, annuleer',
    }).then((result) => {
      if (result.isConfirmed) {
        api.post(SUBSECTOR_URL, { name: newSubsectorName, sector: selectedSectorId })
          .then((response) => {
            setSubsectors([...subsectors, response.data]);
            setNewSubsectorName('');
            Swal.fire(
              'Toegevoegd!',
              'De nieuwe subsector is toegevoegd.',
              'success',
            );
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            Swal.fire(
              'Fout!',
              'Er is iets fout gegaan bij het toevoegen van de subsector.',
              'error',
            );
          });
      }
    });
  };

  const handleAddJob = () => {
    if (!selectedSectorId) {
      Swal.fire('Selecteer eerst een sector.', '', 'info');
      return;
    }

    if (!selectedSubsectorId) {
      Swal.fire('Selecteer eerst een subsector.', '', 'info');
      return;
    }

    Swal.fire({
      title: 'Weet je zeker dat je een nieuw beroep wilt toevoegen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja, voeg toe',
      cancelButtonText: 'Nee, annuleer',
    }).then((result) => {
      if (result.isConfirmed) {
        api.post(PROFESSIONS_URL, {
          job_role: newProfessionName, sector: selectedSectorId, subsector: selectedSubsectorId,
        })
          .then((response) => {
            setProfessions([...professions, response.data]);
            setNewProfessionName('');
            Swal.fire(
              'Toegevoegd!',
              'Het nieuwe beroep is toegevoegd.',
              'success',
            );
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            Swal.fire(
              'Fout!',
              'Er is iets fout gegaan bij het toevoegen van de beroep.',
              'error',
            );
          });
      }
    });
  };

  const handleRowClick = (caseStudy) => {
    navigate('/backoffice/case-study-detail', { state: { caseStudy } });
  };

  const handleSaveEdit = (id) => {
    let url;
    let payload;

    switch (editingItemType) {
      case 'sector':
        url = `${SECTOR_URL}${id}/`;
        payload = { name: editingItemName, nickname: editingItemNickname };
        break;
      case 'subsector':
        url = `${SUBSECTOR_URL}${id}/`;
        payload = { name: editingItemName, nickname: editingItemNickname };
        break;
      case 'profession':
        url = `${PROFESSIONS_URL}${id}/`;
        payload = { job_role: editingItemName, nickname: editingItemNickname };
        break;
      default:
        return;
    }

    api.patch(url, payload)
      .then((response) => {
        // Update the appropriate state array
        switch (editingItemType) {
          case 'sector':
            setSectors(sectors.map((item) => {
              if (item.id === id) {
                return response.data;
              }
              return item;
            }));
            break;
          case 'subsector':
            setSubsectors(subsectors.map((item) => {
              if (item.id === id) {
                return response.data;
              }
              return item;
            }));
            break;
          case 'profession':
            setProfessions(professions.map((item) => {
              if (item.id === id) {
                return response.data;
              }
              return item;
            }));
            break;
          default:
            break;
        }

        // Reset editing state
        setEditingItemId(null);
        setEditingItemName('');
        setEditingItemNickname('');
        setEditingItemType(null);
        setEditingModalOpen(false);
        Swal.fire({
          icon: 'success',
          title: 'Gelukt',
          text: 'Het item is aangepast.',
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => {
        if (error.response.status === 400 && error.response.data.job_role) {
          if (error.response.data.job_role[0] === 'profession with this job role already exists.') {
            Swal.fire({
              icon: 'error',
              title: 'Fout',
              text: 'De gekozen naam bestaat al. Kies een andere naam, of verwijder een beroep als het een duplicaat is.',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Fout',
              text: 'Er is iets fout gegaan bij het wijzigen van het item.',
            });
          }
        }
      });
  };

  const formatEditingItemType = () => {
    if (!editingItemType) return '';
    if (editingItemType === 'profession') return 'Beroep';
    return editingItemType.charAt(0).toUpperCase() + editingItemType.slice(1);
  };

  const renderEditModal = () => (
    <Modal
      open={editingModalOpen}
      onClose={() => setEditingModalOpen(false)}
    >
      <div className="modal-content">
        <h3>
          {/* editingItemType with capital first letter */}
          {formatEditingItemType()}
          {' '}
          wijzigen
        </h3>
        <label>Naam</label>
        <input
          type="text"
          value={editingItemName}
          onChange={(e) => setEditingItemName(e.target.value)}
          placeholder="Naam..."
        />
        <br />
        <label>Bijnaam</label>
        <input
          type="text"
          value={editingItemNickname}
          onChange={(e) => setEditingItemNickname(e.target.value)}
          placeholder="Bijnaam..."
        />
        <Button
          className="btn btn-green"
          startIcon={<TbDeviceFloppy />}
          sx={{ mt: 2 }}
          onClick={() => handleSaveEdit(editingItemId)}
        >
          Opslaan
        </Button>
      </div>
    </Modal>
  );

  function refetchCases(pageNr) {
    setCasesLoading(true);
    setCasesPage(pageNr);
    api.get(
      `${CASE_STUDY_TABLE_URL}?page=${pageNr}${selectedSectorId ? `&occupation__subsector__sector__id=${selectedSectorId}` : ''}${selectedSubsectorId ? `&occupation__subsector__id=${selectedSubsectorId}` : ''}${selectedProfessionId ? `&occupation=${selectedProfessionId}` : ''}`,
    ).then((response) => {
      setCaseStudies(response.data.results);
      setCasesLoading(false);
      setCasesPages(Math.ceil(response.data.count / 10));
    });
  }

  const deleteCaseStudy = (e, caseId) => {
    e.stopPropagation();
    // eslint-disable-next-line no-alert
    Swal.fire({
      icon: 'question',
      title: 'Bevestiging',
      text: 'Weet u zeker dat u deze case wilt verwijderen?',
      showCancelButton: true,
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nee',
    }).then((result) => {
      if (result.isConfirmed) {
        api.del(`${CASE_STUDY_TABLE_URL}${caseId}`)
          .then(() => {
            // Refresh user data
            refetchCases(casesPage);
            Swal.fire({
              icon: 'success',
              title: 'Gelukt',
              text: 'De case is succesvol verwijderd.',
            });
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Fout bij het verwijderen van de case.', error);
          });
      }
    });
  };

  function refetchProfessions(pageNr) {
    setProfessionsLoading(true);
    setProfessionsPage(pageNr);
    api.get(
      `${PROFESSIONS_NO_EMBEDDING_URL}?page=${pageNr}${selectedSectorId ? `&subsector__sector__id=${selectedSectorId}` : ''}
      ${selectedSubsectorId ? `&subsector=${selectedSubsectorId}` : ''}&search=${searchQuery}&without_cases=${onlyWithoutCases ? 'true' : 'false'}`,
    ).then((response) => {
      setProfessions(response.data.results);
      setProfessionsLoading(false);
      setProfessionsPages(Math.ceil(response.data.count / 50));
    });
  }

  const togglePrepublishStatus = async (professionId, status) => {
    try {
      await api.patch(`${PROFESSIONS_URL}${professionId}/`, { prepublish_list: status });
      setProfessions(professions.map((profession) => {
        if (profession.id === professionId) {
          return { ...profession, prepublish_list: status };
        }
        return profession;
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error toggling prepublish status:', error);
    }
  };

  // Helper function to render list items but handle case studies differently
  const renderListItems = (items, clickHandler, itemType) => {
    function isSelected(itemId) {
      if (itemType === 'profession' && selectedProfessionId === itemId) {
        return true;
      }
      if (itemType === 'subsector' && selectedSubsectorId === itemId) {
        return true;
      }
      if (itemType === 'sector' && selectedSectorId === itemId) {
        return true;
      }
      return false;
    }

    function isAll() {
      if (itemType === 'sector' && !selectedSectorId) {
        return true;
      }
      if (itemType === 'subsector' && !selectedSubsectorId) {
        return true;
      }
      if (itemType === 'profession' && !selectedProfessionId && !onlyWithoutCases) {
        return true;
      }
      return false;
    }

    function setAll() {
      if (itemType === 'sector') {
        setSelectedSectorId(null);
        setSelectedSubsectorId(null);
        setSelectedProfessionId(null);
      }
      if (itemType === 'subsector') {
        setSelectedSubsectorId(null);
        setSelectedProfessionId(null);
      }
      if (itemType === 'profession') {
        setSelectedProfessionId(null);
        setOnlyWithoutCases(false);
      }
    }

    const plural = () => {
      switch (itemType) {
        case 'sector':
          return 'sectoren';
        case 'subsector':
          return 'subsectoren';
        case 'profession':
          return 'beroepen';
        default:
          return '';
      }
    };

    const makeOnlyWithoutCases = () => {
      setOnlyWithoutCases(true);
      setSelectedProfessionId(null);
    };

    return (
      <List sx={{ padding: 0 }}>
        <ListItem
          disablePadding
          className={`${isAll() ? 'selected' : ''}`}
          onClick={() => setAll()}
        >
          <ListItemButton>
            <span>
              Alle
              {' '}
              {plural()}
            </span>
          </ListItemButton>
        </ListItem>
        {itemType === 'profession' && (
          <ListItem
            disablePadding
            className={`${onlyWithoutCases ? 'selected' : ''}`}
            onClick={() => makeOnlyWithoutCases()}
          >
            <ListItemButton>
              <span>
                Alleen beroepen met geen gepubliceerde case studies
              </span>
            </ListItemButton>
          </ListItem>
        )}
        {items.map((item) => (
          <ListItem
            key={item.id}
            disablePadding
            className={`${isSelected(item.id) ? 'selected' : ''}`}
          >
            <ListItemButton
              onClick={() => clickHandler(item.id, item.job_role)}
            >
              <span>
                {itemType === 'profession' && item.prepublished_cases_count > 0 && (
                  <Tooltip title="Dit beroep heeft ongepubliceerd case studies." placement="top" arrow>
                    <span>
                      <TbAlertTriangle className="inline-alert-triangle" />
                    </span>
                  </Tooltip>
                )}
                {item.name || item.job_role}
              </span>
              <div className="list-item-buttons">
                {itemType === 'profession' && (
                  <>
                    <input
                      type="checkbox"
                      checked={item.prepublish_list || false}
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation(); // Prevents ListItemButton's onClick from firing
                        togglePrepublishStatus(item.id, e.target.checked);
                      }}
                      id={`prepublish-list-checkbox-${item.id}`}
                    />
                    <label htmlFor={`prepublish-list-checkbox-${item.id}`}><TbCheck /></label>
                  </>
                )}
                <Button
                  className="list-item-button-action delete"
                  sx={{ mr: 1, ml: itemType === 'profession' ? 1 : 0 }}
                  onClick={() => handleDeleteItem(item.id, itemType)}
                >
                  <TbTrash />
                </Button>
                <Button
                  className="list-item-button-action"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents ListItemButton onClick from firing
                    startEditing(item.id, item.name || item.job_role, itemType);
                  }}
                >
                  <TbPencil />
                </Button>
              </div>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  const handleIconChange = (risk, iconId) => {
    // Check if iconId is an empty string and set updatedRisk accordingly
    const updatedRisk = {
      name: risk.name,
      description: risk.description,
      icon: iconId === '' ? null : { id: iconId },
    };
    api.patch(`${RISKS_URL}${risk.id}/`, updatedRisk)
      .then((response) => {
        // Update the risk in the local state
        api.get(SUBSECTOR_URL).then((r) => setSubsectors(r.data));
        Swal.fire({
          icon: 'success',
          title: 'Succes',
          text: `Icon voor "${response.data.name}" succesvol bijgewerkt. Beschrijving: "${response.data.description}"`,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Fout',
          text: `Kan het icon voor "${risk.name}" niet updaten`,
        });
      });
  };

  const openEditModal = (risk) => {
    setCurrentRisk(risk);
    setIsModalOpen(true);
  };

  const handleInputChange = () => {
    setHasRiskChanges(true);
  };

  const closeModal = (bypassConfirmation = false) => {
    if (hasRiskChanges && !bypassConfirmation) {
      Swal.fire({
        title: 'Onopgeslagen wijzigingen',
        text: 'U heeft onopgeslagen wijzigingen. Weet u zeker dat u wilt sluiten?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ja, sluiten',
        cancelButtonText: 'Nee, blijven',
      }).then((result) => {
        if (result.isConfirmed) {
          setIsModalOpen(false);
          setCurrentRisk(null);
          setHasRiskChanges(false);
        }
      });
    } else {
      setIsModalOpen(false);
      setCurrentRisk(null);
      setHasRiskChanges(false);
    }
  };

  const submitRiskUpdate = () => {
    if (!currentRisk) return;

    const updatedDescription = document.getElementById('modal-risk-desc').value;
    if (currentRisk.description === updatedDescription) {
      // Pass true to bypass the confirmation check
      closeModal(true);
      return;
    }

    const updatedRisk = { ...currentRisk, description: updatedDescription };

    api.put(`${RISKS_URL}${currentRisk.id}/`, updatedRisk)
      .then(() => {
        api.get(SUBSECTOR_URL).then((response) => setSubsectors(response.data));
        closeModal(true);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Oeps...',
          text: 'De risk kon niet worden aangepast',
        });
      });
  };

  // eslint-disable-next-line arrow-body-style
  const renderRisks = () => {
    if (!selectedSubsectorId) {
      return <p>Selecteer eerst een subsector.</p>;
    }

    const selectedSubsector = subsectors?.find((subsector) => subsector.id === selectedSubsectorId);

    return selectedSubsector && selectedSubsector.risks.map((risk) => (
      <div key={risk.id} className="risk">
        <div className="input-row">
          <span>{risk.description}</span>
          <Button
            onClick={() => openEditModal(risk)}
            className="btn btn-icon btn-green"
          >
            <TbPencil />
          </Button>
        </div>
        <div className="risk-toolbar">
          <div className="risk-icon">
            {risk.icon ? (
              <img
                alt={`Icon for ${risk.name}`}
                src={`${MEDIA_URL}${risk.icon.icon}`}
              />
            ) : (
              <TbHelpHexagon />
            )}
          </div>
          <select
            value={risk.icon ? risk.icon.id : ''}
            onChange={(e) => handleIconChange(risk, e.target.value)}
          >
            <option value="">Selecteer een icon</option>
            {availableIcons.map((icon) => (
              <option key={icon.id} value={icon.id}>{icon.name}</option>
            ))}
          </select>
        </div>
      </div>
    ));
  };

  const fetchIllnesses = async () => {
    const response = await api.get(GENERAL_ILLNESS_URL);
    setIllnesses(response.data);
  };

  const handleOpen = (illness = { id: null, name: '', percentage: '' }, edit = false) => {
    setCurrentIllness(illness);
    setIsEdit(edit);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentIllness({ id: null, name: '', percentage: '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentIllness({ ...currentIllness, [name]: value });
  };

  const saveIllness = async () => {
    if (isEdit) {
      await api.put(`${GENERAL_ILLNESS_URL}${currentIllness.id}/`, currentIllness);
    } else {
      await api.post(GENERAL_ILLNESS_URL, currentIllness);
    }
    fetchIllnesses();
    handleClose();
  };

  const deleteIllness = async (id) => {
    await api.del(`${GENERAL_ILLNESS_URL}${id}/`);
    fetchIllnesses();
  };

  // Fetch Sectors
  useEffect(() => {
    if (hasAccess) {
      api.get(SECTOR_URL).then((response) => setSectors(response.data));
      api.get(SUBSECTOR_URL).then((response) => setSubsectors(response.data));
      refetchProfessions(1);
      refetchCases(1);
      fetchIllnesses();
    }
  }, [hasAccess]);

  // Fetch available icons once
  useEffect(() => {
    if (hasAccess) {
      api.get(RISKS_ICONS_URL).then((response) => {
        setAvailableIcons(response.data);
      });
    }
  }, [hasAccess]);

  useEffect(() => {
    refetchProfessions(1);
    refetchCases(1);
  }, [selectedSectorId, selectedSubsectorId, searchQuery, onlyWithoutCases]);

  useEffect(() => {
    refetchCases(1);
    if (selectedProfessionId) {
      setOnlyWithoutCases(false);
    }
  }, [selectedProfessionId]);

  useEffect(() => {
    if (selectedSubsectorId) {
      const subsector = subsectors?.find((sub) => sub.id === selectedSubsectorId);
      if (!subsector) return;
      if (subsector.sector !== selectedSectorId) {
        setSelectedSubsectorId(null);
        setSelectedProfessionId(null);
      } else {
        const profession = professions?.find((prof) => prof.id === selectedProfessionId);
        if (profession?.subsector !== selectedSubsectorId) {
          setSelectedProfessionId(null);
        }
      }
    }
  }, [selectedSectorId]);

  if (!userContext.user || !userContext.user.id) {
    return <LoadingScreen />;
  }

  if (!hasAccess) {
    return <AccessDeniedMessage />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 6, mb: 9, minHeight: 'calc(100vh - 250px)' }} className="case-content-dashboard">
      {renderEditModal()}
      <Collapse in={alertOpen}>
        <Alert
          severity="info"
          sx={{ mb: 3 }}
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setAlertOpen(false)}
            >
              <TbX />
            </IconButton>
          )}
        >
          Door een groen vinkje aan een beroep toe te voegen, wordt het beschikbaar in de generator.
        </Alert>
      </Collapse>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <div className="dashboard-panel compact">
            <div className="panel-header">
              <h2>Sectoren</h2>
            </div>
            <div className="panel-content" style={{ height: 360, overflow: 'auto' }}>
              {sectors ? (
                <>
                  {sectors.length > 0
                    ? renderListItems(sectors, setSelectedSectorId, 'sector')
                    : <p>Geen sectoren gevonden.</p>}
                </>
              ) : <CircularProgress />}
            </div>
          </div>
          <div className="list-add">
            <input
              type="text"
              value={newSectorName}
              onChange={(e) => setNewSectorName(e.target.value)}
              placeholder="Nieuwe sectornaam"
            />
            <Button
              onClick={handleAddSector}
            >
              <TbPlus />
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="dashboard-panel compact">
            <div className="panel-header">
              <h2>Subsectoren</h2>
            </div>
            <div className="panel-content" style={{ height: 360, overflow: 'auto' }}>
              {subsectors ? (
                <>
                  {
                    subsectors.length > 0
                      ? renderListItems(
                        selectedSectorId
                          ? subsectors.filter((subsector) => subsector.sector === selectedSectorId)
                          : subsectors,
                        setSelectedSubsectorId,
                        'subsector',
                      )
                      : <p>Geen subsectoren gevonden.</p>
                  }
                </>
              ) : <CircularProgress />}
            </div>
          </div>
          <div className="list-add">
            <input
              type="text"
              value={newSubsectorName}
              onChange={(e) => setNewSubsectorName(e.target.value)}
              placeholder="Nieuwe subsectornaam"
            />
            <Button
              onClick={handleAddSubsector}
            >
              <TbPlus />
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <div className="dashboard-panel compact">
            <div className="panel-header">
              <h2>Beroepen</h2>
            </div>
            <div className="search-bar">
              <input
                type="text"
                placeholder="Zoek beroepen..."
                value={searchQuery}
                className="search-input"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="panel-content">
              <div style={{ height: 260, overflow: 'auto' }}>
                {!professionsLoading && professions ? (
                  <>
                    {
                      professions.length > 0
                        ? renderListItems(professions, setSelectedProfessionId, 'profession')
                        : <p>Geen beroepen gevonden.</p>
                    }
                  </>
                ) : <CircularProgress />}
              </div>
              <div className="pagination">
                <Pagination
                  count={professionsPages}
                  page={professionsPage}
                  onChange={(e, value) => refetchProfessions(value)}
                  boundaryCount={1}
                />
              </div>
            </div>
          </div>
          <div className="list-add">
            <input
              type="text"
              value={newProfessionName}
              onChange={(e) => setNewProfessionName(e.target.value)}
              placeholder="Nieuw beroep"
            />
            <Button
              onClick={handleAddJob}
            >
              <TbPlus />
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="dashboard-panel">
            <div className="panel-header">
              <TbAlertTriangle />
              {' '}
              <h2>Subsector risico&apos;s</h2>
            </div>
            <div
              className="panel-content"
              style={{ height: 300, overflow: 'auto' }}
            >
              {renderRisks()}
              <Modal
                open={isModalOpen}
                onClose={() => closeModal(false)}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <div
                  className="modal-content"
                  style={{
                    width: '50%', padding: '20px', boxSizing: 'border-box', backgroundColor: 'white',
                  }}
                >
                  <h3>Wijzig Risk Beschrijving</h3>
                  <input
                    id="modal-risk-desc"
                    type="text"
                    defaultValue={currentRisk ? currentRisk.description : ''}
                    onChange={handleInputChange}
                    style={{
                      width: '100%', padding: '10px', fontSize: '16px', margin: '10px 0', boxSizing: 'border-box',
                    }}
                  />
                  <Button
                    onClick={submitRiskUpdate}
                    className="btn btn-green"
                    style={{ padding: '10px 20px', fontSize: '16px' }}
                  >
                    Opslaan
                  </Button>
                </div>
              </Modal>

            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="dashboard-panel">
            <div className="panel-header">
              <h2>Cases</h2>
            </div>
            <div className="panel-content">
              {!casesLoading && caseStudies ? (
                <>
                  {
                    caseStudies.length > 0 && !onlyWithoutCases ? (
                      <TableContainer sx={{ mt: -2 }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell><b>Naam</b></TableCell>
                              <TableCell><b>Beroep</b></TableCell>
                              <TableCell><b>Leeftijd</b></TableCell>
                              <TableCell><b>Sector</b></TableCell>
                              <TableCell><b>Subsector</b></TableCell>
                              <TableCell><b>Aantal Matches</b></TableCell>
                              <TableCell><b>Acties</b></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {caseStudies.map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell>{row.first_name}</TableCell>
                                <TableCell>{row.occupation.job_role}</TableCell>
                                <TableCell>{row.profession_level}</TableCell>
                                <TableCell>
                                  {sectors && sectors.find(
                                    (sector) => sector.id === row.occupation.subsector.sector,
                                  ).name}
                                </TableCell>
                                <TableCell>{row.occupation.subsector.name}</TableCell>
                                <TableCell>{row.matches_count}</TableCell>
                                <TableCell>
                                  <div style={{ display: 'flex' }}>
                                    <Button
                                      className="btn btn-red btn-icon-2"
                                      onClick={(e) => deleteCaseStudy(e, row.id)}
                                      sx={{ mr: 1, display: 'inline' }}
                                    >
                                      <TbTrash />
                                    </Button>
                                    <Button
                                      className="btn btn-green btn-icon-2"
                                      onClick={() => handleRowClick(row)}
                                      sx={{ display: 'inline' }}
                                    >
                                      <TbArrowRight />
                                    </Button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : <p>Geen cases gevonden.</p>
                  }
                  <div className="pagination">
                    <Pagination
                      count={casesPages}
                      page={casesPage}
                      onChange={(e, value) => refetchCases(value)}
                      boundaryCount={1}
                    />
                  </div>
                </>
              ) : <CircularProgress />}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="dashboard-panel">
            <div
              className="panel-header"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingTop: 9,
                paddingBottom: 9,
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TbFaceMask />
                {' '}
                <h2>Algemene aandoeningen</h2>
              </div>
              <Button
                className="btn btn-white btn-small"
                startIcon={<TbPlus />}
                onClick={() => handleOpen()}
              >
                Nieuw
              </Button>
            </div>
            <div className="panel-content" style={{ height: 360, overflow: 'auto', padding: 0 }}>
              <div>
                <List>
                  {illnesses.map((illness) => (
                    <ListItem
                      key={illness.id}
                    >
                      <ListItemButton>
                        <span>
                          {illness.name}
                          {' '}
                          <br />
                          <small>
                            Percentage:
                            {' '}
                            {illness.percentage}
                          </small>
                        </span>
                        <div className="list-item-buttons">
                          <Button
                            onClick={() => handleOpen(illness, true)}
                            className="btn btn-green btn-icon-2"
                            sx={{ mr: 1 }}
                          >
                            <TbPencil />
                          </Button>
                          <Button
                            className="btn btn-red btn-icon-2"
                            onClick={() => deleteIllness(illness.id)}
                          >
                            <TbTrash />
                          </Button>
                        </div>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
                <Modal open={open} onClose={handleClose}>
                  <div className="modal-content">
                    <h3>
                      {isEdit ? 'Wijzig aandoening' : 'Voeg nieuwe aandoening toe'}
                    </h3>
                    <label>Aandoening</label>
                    <input
                      type="text"
                      name="name"
                      value={currentIllness.name}
                      onChange={handleChange}
                      placeholder="Aandoening..."
                    />
                    <label style={{ marginTop: 20 }}>Percentage</label>
                    <input
                      type="number"
                      name="percentage"
                      value={currentIllness.percentage}
                      onChange={handleChange}
                      placeholder="Percentage..."
                      step="0.01"
                    />
                    <Button
                      className="btn btn-green"
                      startIcon={<TbDeviceFloppy />}
                      sx={{ mt: 2 }}
                      onClick={saveIllness}
                    >
                      Opslaan
                    </Button>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CaseContentDashboard;
