const jsonToCSV = (jsonArray) => {
  // Handle empty array case
  if (jsonArray.length === 0) return '';
  const csvRows = [];
  const headers = Object.keys(jsonArray[0]);
  csvRows.push(headers.join(',')); // Add header row

  jsonArray.forEach((row) => {
    const values = headers.map((header) => {
      // eslint-disable-next-line prefer-template
      const escaped = ('' + row[header]).replace(/"/g, '\\"'); // Handle escaping quotes
      return `${escaped}`;
    });
    csvRows.push(values.join(','));
  });

  return csvRows.join('\n');
};

export default jsonToCSV;
