import React, {
  useState, useContext, useEffect,
} from 'react';
import {
  Container, Button, IconButton, Box, Grid, Tooltip, Alert,
} from '@mui/material';
import { AsyncPaginate } from 'react-select-async-paginate';
import Swal2 from 'sweetalert2';
import {
  TbTrash, TbLoader3, TbBook, TbX,
} from 'react-icons/tb';
import { useNavigate, useParams } from 'react-router-dom';
import { IconCircleDashedCheck, IconDeviceFloppy, IconTrash } from '@tabler/icons-react';
import { useDropzone } from 'react-dropzone';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { SOURCES_URL, TOPICS_LIGHT_URL } from '../../../../Constants/URLS';
import { containerStyles } from '../../../../Components/TableStyles/TableStyles';
import '../../ChatBackoffice.scss';
import ModerationComponent from '../../../../Components/ModerationComponent';
import TestChat from '../../../../Components/Chat/TestChat';
import sourceIcon from '../../../../Utils/SourceUtils';
import topicsStyles from '../../topicStyles';

function SourceEditView() {
  const { id } = useParams();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const navigate = useNavigate();
  const maxChars = 255;

  const [title, setTitle] = useState('');
  const [additionalText, setAdditionalText] = useState('');
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [originalSource, setOriginalSource] = useState(null);
  const [webLink, setWebLink] = useState('');
  const [webLinkError, setWebLinkError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sourceValid, setSourceValid] = useState(false);
  const [webLinkValid, setWebLinkValid] = useState(false);

  const [testOpen, setTestOpen] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'text/plain': ['.txt'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    maxFiles: 1,
  });

  const isValidURL = (string) => {
    const res = string.match(/(https?:\/\/[^\s]+)/g);
    return res !== null;
  };

  const handleWebLinkChange = (e) => {
    const url = e.target.value;
    setWebLink(url);
    if (url && !isValidURL(url)) {
      setWebLinkError('Ongeldige URL');
    } else {
      setWebLinkError('');
    }
  };

  const handleTitleChange = (e) => {
    if (e.target.value.length <= maxChars) {
      setTitle(e.target.value);
    }
  };

  const handleSelectTopics = (selectedOptions) => {
    setSelectedTopics(selectedOptions || []);
  };

  const loadTopics = async (search) => {
    const response = await api.get(`${TOPICS_LIGHT_URL}?search=${search}`);

    const loadedTopics = {
      options: response.data.map((topic) => ({
        value: topic.id,
        label: topic.name,
      })),
      hasMore: false,
    };

    return loadedTopics;
  };

  const fetchSourceData = async () => {
    try {
      const response = await api.get(`${SOURCES_URL}${id}/`);
      const sourceData = response.data;
      setTitle(sourceData.title);
      setAdditionalText(sourceData.additional_text || '');
      setWebLink(sourceData.web_link || '');
      setOriginalSource(sourceData);

      // Fetch and set topics
      const topicsResponse = await api.get(TOPICS_LIGHT_URL);
      const matchedTopics = sourceData.topics.map((topicId) => {
        const topic = topicsResponse.data.find((t) => t.id === topicId);
        return {
          value: topic.id,
          label: topic.name,
        };
      });
      setSelectedTopics(matchedTopics);
    } catch (error) {
      Swal2.fire({
        title: 'Fout',
        text: `Kan de bron niet ophalen: ${error.response ? error.response.data.message : error.message}`,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    fetchSourceData();
  }, [id]);

  const updateSource = async () => {
    setIsLoading(true);
    try {
      // Prepare form data
      const formData = new FormData();
      formData.append('title', title);
      formData.append('additional_text', additionalText);

      if (webLink) {
        formData.append('web_link', webLink);
      }

      if (acceptedFiles.length > 0) {
        formData.append('source_file', acceptedFiles[0]);
      }

      // Update the source
      const response = await api.patch(`${SOURCES_URL}${id}/`, formData);

      if (response.status === 200) {
        const sourceId = response.data.id;

        // Update topics separately
        const topicsData = {
          topics: selectedTopics.map((topic) => topic.value),
        };
        await api.patch(`${SOURCES_URL}${sourceId}/`, topicsData);

        Swal2.fire({
          title: 'Succesvol',
          text: 'De bron is bijgewerkt.',
          icon: 'success',
        });

        // Redirect to sources list
        navigate('/backoffice/sources');
      }
    } catch (error) {
      Swal2.fire({
        title: 'Fout',
        text: `Er is een fout opgetreden bij het bijwerken van de bron: ${error.response ? error.response.data.message : error.message}`,
        icon: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSource = async () => {
    Swal2.fire({
      title: 'Weet je het zeker?',
      text: 'Dit kan niet ongedaan worden gemaakt!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, verwijder het!',
      cancelButtonText: 'Annuleren',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.del(`${SOURCES_URL}${id}`);
          Swal2.fire(
            'Verwijderd!',
            'Het bron is verwijderd.',
            'success',
          );
          navigate('/backoffice/sources');
        } catch (error) {
          Swal2.fire({
            title: 'Fout',
            text: `Kan het bron niet verwijderen: ${error.response ? error.response.data.message : error.message}`,
            icon: 'error',
          });
        }
      }
    });
  };

  const handleDeleteSource = () => {
    Swal2.fire({
      title: 'Weet je het zeker?',
      text: 'Dit kan niet ongedaan worden gemaakt!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, verwijder het!',
      cancelButtonText: 'Annuleren',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Patch the source to remove the source file
          const formData = new FormData();
          formData.append('source_file', '');

          await api.patch(`${SOURCES_URL}${id}/`, formData);

          // Refetch the source data to update the form
          await fetchSourceData();

          Swal2.fire(
            'Succesvol',
            'Het bronbestand is verwijderd.',
            'success',
          );
        } catch (error) {
          Swal2.fire({
            title: 'Fout',
            text: `Kan het bronbestand niet verwijderen: ${error.response ? error.response.data.message : error.message}`,
            icon: 'error',
          });
        }
      }
    });
  };

  // Update form validity
  useEffect(() => {
    const isTitleValid = title.trim().length > 0;
    const isAdditionalTextValid = additionalText.trim().length > 0;
    const isSourceFileValid = acceptedFiles.length > 0
      || (originalSource && originalSource.source_file);
    setSourceValid(isSourceFileValid);
    const isWebLinkValid = !webLink || (webLink && !webLinkError);
    setWebLinkValid(webLink && !webLinkError);
    const isValid = (isTitleValid && isAdditionalTextValid)
      || isSourceFileValid
      || (isAdditionalTextValid && !isSourceFileValid);
    setIsFormValid(isValid && isWebLinkValid);
  }, [title, additionalText, acceptedFiles, webLink, webLinkError]);

  return (
    <Container maxWidth="lg" sx={containerStyles}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className="heading" style={{ marginBottom: 0 }}>
          <TbBook />
          <h1>Bewerk bron</h1>
        </div>
        <div>
          <Button
            className="btn btn-red"
            startIcon={<TbTrash />}
            onClick={deleteSource}
            sx={{ mr: 1 }}
          >
            Verwijder
          </Button>
          <Button
            className="btn btn-green"
            startIcon={<IconCircleDashedCheck />}
            onClick={() => setTestOpen(true)}
          >
            Test Bron
          </Button>
        </div>
        <TestChat
          title={title}
          id={Number(id)}
          open={testOpen}
          close={() => setTestOpen(false)}
        />
      </Box>
      <div className="chat-backoffice-view">
        <h4>Titel</h4>
        <input
          value={title}
          onChange={handleTitleChange}
          placeholder="Voer hier een titel in..."
        />
        <span className={`helper ${title.length === maxChars ? 'error' : ''}`}>
          {`${title.length}/${maxChars}`}
        </span>
        <h4>Bronbestand</h4>
        {!sourceValid && !webLinkValid && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Je moet ofwel een bronbestand uploaden of een web link invoeren.
          </Alert>
        )}
        <Grid container spacing={4}>
          <Grid item xs={12} lg={2}>
            <label>Huidig bestand:</label>
            {originalSource && originalSource.source_file ? (
              <div className="current-file-container">
                <Tooltip title={originalSource.source_file.split('/').pop()} arrow placement="top">
                  {/* eslint-disable-next-line */}
                  <div className="current-file" onClick={() => window.open(originalSource.source_file)}>
                    <img src={sourceIcon(originalSource)} alt="source-icon" />
                    {originalSource.source_file.split('/').pop().slice(0, 17)}
                    ...
                  </div>
                </Tooltip>
                <Tooltip title="Huidige bron verwijderen" arrow placement="top">
                  <IconButton
                    className="btn-delete"
                    onClick={() => handleDeleteSource(originalSource.id)}
                  >
                    <TbX />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div className="current-file">
                Geen bestand
              </div>
            )}
          </Grid>
          <Grid item xs={12} lg={10}>
            <label>Nieuw bestand:</label>
            {/* eslint-disable-next-line */}
            <div {...getRootProps({className: 'dropzone'})}>
              {/* eslint-disable-next-line */}
              <input {...getInputProps()} />
              {acceptedFiles.length > 0 ? (
                <Tooltip title="Verwijder bestand" arrow placement="top">
                  <div className="dropzone-file">
                    <img src={sourceIcon({ source_file: acceptedFiles[0].path })} alt="source-icon" />
                    {acceptedFiles[0].path}
                    <div className="overlay">
                      <IconTrash />
                    </div>
                  </div>
                </Tooltip>
              ) : (
                <p>Drag & drop het bestand hierheen of klik om een bestand te selecteren.</p>
              )}
            </div>
          </Grid>
        </Grid>
        <h4>Aanvullende tekst</h4>
        <textarea
          rows={4}
          value={additionalText}
          onChange={(e) => setAdditionalText(e.target.value)}
          placeholder="Typ hier je aanvullende tekst..."
        />
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <h4>Onderwerpen</h4>
            <AsyncPaginate
              loadOptions={loadTopics}
              onChange={handleSelectTopics}
              isSearchable
              isClearable
              additional={{}}
              isMulti
              debounceTimeout={300}
              placeholder="Selecteer onderwerpen..."
              value={selectedTopics}
              styles={topicsStyles}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <h4>Web Link</h4>
            <input
              value={webLink}
              onChange={handleWebLinkChange}
              placeholder="Voer hier een web link in..."
            />
            {!!webLinkError && (
              <span className="helper error">{webLinkError}</span>
            )}
          </Grid>
        </Grid>
        <ModerationComponent modelType="source" userContext={userContext} />
        <Button
          startIcon={isLoading ? <TbLoader3 size={20} className="loader" /> : <IconDeviceFloppy />}
          className="btn btn-green"
          onClick={updateSource}
          disabled={!isFormValid || isLoading}
          sx={{ mt: 3 }}
        >
          Bijwerken
        </Button>
      </div>
    </Container>
  );
}

export default SourceEditView;
