import React, { useContext, useEffect } from 'react';
import { Button, Container } from '@mui/material';
import { TbLockCheck } from 'react-icons/tb';
import { FiUserPlus } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import './LandingView.scss';

function LandingView() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.email) {
      navigate('/home');
    }
  }, [user]);

  return (
    <div className="landing-page">
      <Container maxWidth="xl">
        <div className="landing-page-header">
          <div>
            <a href="/"><img src="/logo-platform-dark.png" alt="logo" /></a>
          </div>
          <div>
            <a href="https://www.creditlife.nl/" target="_blank" rel="noreferrer">
              <img src="/powered-by-creditlife.png" alt="CreditLife" />
            </a>
          </div>
        </div>
        <div className="landing-page-content">
          {/* <TbUmbrella /> */}
          <h1>Welkom op het AO Platform</h1>
          <p>
            {/* eslint-disable-next-line max-len */}
            Arbeidsongeschiktheid: een complex inkomensrisico, begrijpelijk en toegankelijk inzichtelijk gemaakt voor jou én je klant.
            <br />
            <br />
            {/* eslint-disable-next-line max-len */}
            Doe je voordeel met praktijkvoorbeelden, de inkomensplanner of slimme handvatten uit onze AO-toolkit. Ieder adviesgesprek weer!
          </p>
          <Button
            href="/login"
            startIcon={<TbLockCheck />}
            className="btn btn-white"
            sx={{ mt: 3 }}
          >
            Inloggen
          </Button>
          <Button
            href="/signup"
            startIcon={<FiUserPlus />}
            className="btn"
            sx={{ mt: 3, pr: 0 }}
          >
            Registreer je hier
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default LandingView;
