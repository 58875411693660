import apiUtils from '../Utils/ApiUtils';
import {
  ACTIVE_USERS_MATCHES_URL, TOTAL_MATCHES_URL, AVERAGE_CLIENT_AGE_URL,
  PROFESSION_POPULARITY_URL, CASE_STUDY_POPULARITY_URL, USER_MATCHES_URL,
  COUNT_PDF_DOWNLOADS_URL, BADLY_MATCHED_JOBS, PROFESSIONS_URL,
} from '../Constants/URLS';

const DataDashboardApi = (userContext) => {
  const utils = apiUtils(userContext);

  return {
    getBadlyMatchedJobRoles(params) {
      return utils.get(BADLY_MATCHED_JOBS, { params });
    },

    getActiveUsersMatches(params) {
      return utils.get(ACTIVE_USERS_MATCHES_URL, { params });
    },

    getTotalMatches(params) {
      return utils.get(TOTAL_MATCHES_URL, { params });
    },

    getAverageClientAge(params) {
      return utils.get(AVERAGE_CLIENT_AGE_URL, { params });
    },

    getProfessionPopularity(params) {
      return utils.get(PROFESSION_POPULARITY_URL, { params });
    },

    getCaseStudyPopularity(params) {
      return utils.get(CASE_STUDY_POPULARITY_URL, { params });
    },

    getUserMatches(params) {
      return utils.get(USER_MATCHES_URL, { params });
    },

    getPDFDownloads(params) {
      return utils.get(COUNT_PDF_DOWNLOADS_URL, { params });
    },

    hideProfessionFromBadlyMatched(id) {
      return utils.patch(`${PROFESSIONS_URL}${id}/`, { hide_from_badly_matched: true });
    },
  };
};

export default DataDashboardApi;
