import apiUtilsHook from '../Utils/ApiUtils';
import {
  USER_DETAILS_URL, CHANGE_PASSWORD_URL, JOB_ROLES_URL, USER_URL,
} from '../Constants/URLS';

const UserProfileApi = (config) => {
  const utils = apiUtilsHook(config);

  return {
    getUserDetails() {
      return utils.get(USER_DETAILS_URL);
    },
    updateUserDetails(userData) {
      return utils.put(USER_DETAILS_URL, userData);
    },
    changeUserPassword(passwordData) {
      return utils.post(CHANGE_PASSWORD_URL, {
        new_password1: passwordData.newPassword,
        new_password2: passwordData.confirmNewPassword,
      });
    },
    getJobRoles() {
      return utils.get(JOB_ROLES_URL);
    },
    deleteAccount(userId) {
      return utils.del(`${USER_URL}${userId}/delete_account/`);
    },
  };
};

export default UserProfileApi;
