import React from 'react';
import {
  TbHourglass, TbSmartHome, TbChartHistogram, TbUsers, TbFolders, TbTags,
  TbFolderPlus, TbMessage2Exclamation, TbMessages, TbBook,
} from 'react-icons/tb';
import { IconHelpOctagon, IconZoom } from '@tabler/icons-react';
import { isProduction } from '../Utils/EnvUtils';
import ClientForm from '../Views/ClientForm';
import SignupView from '../Views/SignUpView';
import BestMatchesView from '../Views/BestMatchesView';
import HomePage from '../Views/HomePage';
import LandingView from '../Views/LandingView';
import LoginView from '../Views/Login';
import ProfileSettingsView from '../Views/ProfileSettingsView';
import DataDashboardView from '../Views/DataDashboardView';
import UserTableView from '../Views/UserTableView/UserTableView';
import PrivacyPolicy from '../Views/PrivacyPolicy';
import TermsAndConditions from '../Views/TermsAndConditions';
import FeedbackTable from '../Views/FeedbackView/FeedbackView';
import CaseContentDashboard from '../Views/CaseContentDashboard';
import CaseStudyGenerator from '../Views/CaseStudyGenerator';
import ChatView from '../Views/ChatView';
import SourcesTableView from '../Views/ChatBackoffice/Sources/SourcesTableView';
import FAQTableView from '../Views/ChatBackoffice/FAQ/FAQTableView';
import TopicsTableView from '../Views/ChatBackoffice/Topics/TopicsTableView';
import TopicAddView from '../Views/ChatBackoffice/Topics/TopicAddView';
import FAQAddView from '../Views/ChatBackoffice/FAQ/FAQAddView';
import SourceAddView from '../Views/ChatBackoffice/Sources/SourceAddView';
import TopicEditView from '../Views/ChatBackoffice/Topics/TopicEditView';
import FAQEditView from '../Views/ChatBackoffice/FAQ/FAQEditView';
import SourceEditView from '../Views/ChatBackoffice/Sources/SourceEditView';
import ConversationTableView from '../Views/ConversationTableView';
import PasswordResetConfirm from '../Views/PasswordResetConfirm';
import ConfirmEmail from '../Views/ConfirmEmail';
import LegalTexts from '../Views/LegalTexts';

const isProdEnv = isProduction();

export default function Pages() {
  return {
    landing: {
      id: 1,
      path: '/',
      loginNeeded: false,
      showMenu: false,
      element: <LandingView />,
    },
    home: {
      id: 2,
      menuIcon: <TbSmartHome />,
      showInMenu: true,
      path: '/home',
      loginNeeded: true,
      showChatbot: true,
      showMenu: true,
      navName: 'Home',
      element: <HomePage />,
    },
    signup: {
      id: 3,
      path: '/signup',
      loginNeeded: false,
      showMenu: false,
      element: <SignupView />,
    },
    login: {
      id: 4,
      showMenu: false,
      loginNeeded: false,
      path: '/login',
      element: <LoginView />,
    },
    client_form: {
      id: 5,
      menuIcon: <IconZoom />,
      showInMenu: true,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      navName: 'Vind een risicomatch',
      path: '/client-form',
      element: <ClientForm />,
    },
    best_matches: {
      id: 6,
      menuIcon: <TbHourglass />,
      showInMenu: false,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      navName: 'Geschiedenis',
      path: '/matches',
      element: <BestMatchesView />,
    },
    profile: {
      id: 7,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      navName: 'Profiel',
      path: '/profile',
      element: <ProfileSettingsView />,
    },
    data_dashboard: {
      id: 8,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      menuIcon: <TbChartHistogram />,
      navName: 'Data dashboard',
      path: '/backoffice/data-dashboard',
      roles: ['Admin', 'Staff'],
      element: <DataDashboardView />,
    },
    user_table: {
      id: 9,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      menuIcon: <TbUsers />,
      navName: 'Gebruikers',
      path: '/backoffice/user-table',
      roles: ['Admin', 'Staff'],
      element: <UserTableView />,
    },
    privacy_policy: {
      id: 10,
      showMenu: true,
      loginNeeded: false,
      showChatbot: false,
      path: '/privacyverklaring',
      element: <PrivacyPolicy />,
    },
    terms_and_conditions: {
      id: 11,
      showMenu: true,
      loginNeeded: false,
      showChatbot: false,
      path: '/gebruikersvoorwaarden',
      element: <TermsAndConditions />,
    },
    cases: {
      id: 12,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/cases',
      element: <CaseContentDashboard />,
      roles: ['Admin', 'Staff'],
      navName: 'Case beheer',
      menuIcon: <TbFolders />,
    },
    case_content_generator: {
      id: 13,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/case-content-generator',
      element: <CaseStudyGenerator />,
      roles: ['Admin', 'Staff'],
      navName: 'Case generator',
      menuIcon: <TbFolderPlus />,
    },
    feedback: {
      id: 14,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/feedback',
      element: <FeedbackTable />,
      roles: ['Admin', 'Staff'],
      navName: 'Feedback',
      menuIcon: <TbMessage2Exclamation />,
    },
    chat: {
      id: 15,
      showInMenu: !isProdEnv,
      showMenu: !isProdEnv,
      loginNeeded: true,
      showChatbot: false,
      path: '/chat',
      element: <ChatView />,
      navName: 'Chat',
      menuIcon: <TbMessages />,
    },
    sources_table: {
      id: 16,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/sources',
      element: <SourcesTableView />,
      roles: ['Admin', 'Staff'],
      navName: 'Bronnen',
      menuIcon: <TbBook />,
    },
    faq_table: {
      id: 17,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/faqs',
      element: <FAQTableView />,
      roles: ['Admin', 'Staff'],
      navName: 'Veelgestelde vragen',
      menuIcon: <IconHelpOctagon />,
    },
    topics_table: {
      id: 18,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/topics',
      element: <TopicsTableView />,
      roles: ['Admin', 'Staff'],
      navName: 'Onderwerpen',
      menuIcon: <TbTags />,
    },
    add_topic: {
      id: 19,
      showMenu: false,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/topics/add',
      element: <TopicAddView />,
      roles: ['Admin', 'Staff'],
    },
    add_faq: {
      id: 20,
      showMenu: false,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/faqs/add',
      element: <FAQAddView />,
      roles: ['Admin', 'Staff'],
    },
    add_source: {
      id: 21,
      showMenu: false,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/sources/add',
      element: <SourceAddView />,
      roles: ['Admin', 'Staff'],
    },
    edit_topic: {
      id: 22,
      showMenu: false,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/topics/edit/:id',
      element: <TopicEditView />,
      roles: ['Admin', 'Staff'],
    },
    edit_faq: {
      id: 23,
      showMenu: false,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/faqs/edit/:id',
      element: <FAQEditView />,
      roles: ['Admin', 'Staff'],
    },
    edit_source: {
      id: 24,
      showMenu: false,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/sources/edit/:id',
      element: <SourceEditView />,
      roles: ['Admin', 'Staff'],
    },
    password_reset_confirm: {
      id: 25,
      showMenu: false,
      loginNeeded: false,
      showChatbot: false,
      path: '/reset-password/:uidb64/:token',
      element: <PasswordResetConfirm />,
    },
    confirm_email: {
      id: 26,
      showMenu: false,
      loginNeeded: false,
      showChatbot: false,
      path: '/confirm-email/:key',
      element: <ConfirmEmail />,
    },
    conversations_table: {
      id: 27,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/conversations',
      element: <ConversationTableView />,
      roles: ['Admin', 'Staff'],
      navName: 'Gesprekken',
      menuIcon: <TbMessages />,
    },
    legal_texts: {
      id: 28,
      showMenu: true,
      loginNeeded: true,
      path: '/backoffice/legal-texts',
      element: <LegalTexts />,
      roles: ['Admin'],
      navName: 'Legal texts',
    },
  };
}
