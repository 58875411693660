import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button, Container, Grid, Modal,
} from '@mui/material';
import {
  TbArrowRight, TbMoneybag, TbUsersGroup, TbSwitch,
  TbFileDownload, TbLoader3, TbArrowLeft, TbHelpHexagon,
} from 'react-icons/tb';
import { IconZoom } from '@tabler/icons-react';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import {
  CLIENTS_URL, MATCHES_URL, MEDIA_URL, PDF_DOWNLOADS_URL, PROFESSIONS_URL,
} from '../../Constants/URLS';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import './MatchesDetailView.scss';
import LoadingScreen from '../LoadingScreen';

function MatchesDetailView() {
  const [matches, setMatches] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const clientId = location.state?.clientId;
  const rankingSimilarity = location.state?.rankingSimilarity;
  const [modalOpen, setModalOpen] = useState(false);
  const [clientData, setClientData] = useState(null);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const [pdfFile, setPdfFile] = useState(null);
  const [currentMatch, setCurrentMatch] = useState(null);

  const handleBackToForm = () => {
    if (clientData) {
      navigate('/client-form', {
        state: {
          formState: {
            age: clientData.age,
            numChildren: clientData.num_children,
            profession: clientData.profession,
            selectedGender: clientData.gender,
            selectedMaritalStatus: clientData.marital_status,
            hasChildren: clientData.num_children > 0,
          },
        },
      });
    }
  };

  const fetchClientAndProfessionData = async () => {
    try {
      const clientResponse = await api.get(`${CLIENTS_URL}${clientId}`);
      const fetchedClientData = clientResponse.data;

      // Fetch the profession data using the occupation ID
      const professionResponse = await api.get(`${PROFESSIONS_URL}${fetchedClientData.occupation}`);
      const professionData = professionResponse.data;

      return {
        ...fetchedClientData,
        profession: professionData.job_role,
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching data:', error);
      return null;
    }
  };

  useEffect(() => {
    if (clientId) {
      fetchClientAndProfessionData().then((data) => {
        if (data) {
          setClientData(data);
        }
      });
    }
  }, [clientId]);

  useEffect(() => {
    if (clientId) {
      const fetchMatches = async () => {
        try {
          const response = await api.get(`${CLIENTS_URL}${clientId}/matches/`);
          setMatches(response.data);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching matches:', error);
        }
      };

      fetchMatches();
    }
  }, [clientId, api]);

  useEffect(() => {
    if (matches && !currentMatch && rankingSimilarity !== undefined) {
      const selectedMatch = matches.find((match) => match.ranking_similarity === rankingSimilarity);
      setCurrentMatch(selectedMatch || matches[0]);
    } else if (matches && !currentMatch) {
      setCurrentMatch(matches[0]);
    }
  }, [matches, rankingSimilarity]);

  const handleChangeMatch = (match) => {
    setCurrentMatch(match);
    setModalOpen(false);
    setPdfFile(null);
  };

  const recordPdfDownload = (matchId) => {
    api.post(PDF_DOWNLOADS_URL, {
      match: matchId,
      downloaded: true,
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error recording PDF download:', error);
    });
  };

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = pdfFile;
    link.target = '_blank'; // local debug
    // add pdf name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    recordPdfDownload(currentMatch.id);
  };

  useEffect(() => {
    if (currentMatch) {
      const pdfUrl = `${MATCHES_URL}/${currentMatch.id}/pdf`;
      api.get(pdfUrl, {
        responseType: 'blob',
      }).then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        setPdfFile(fileURL);
      });
    } else {
      setPdfFile(null);
    }
  }, [currentMatch]);

  // Loading condition
  if (!clientData || !matches || !currentMatch) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth="lg" sx={{ pt: 10, pb: 9 }}>
      {currentMatch && (
        <div className="single-match">
          <div className="header">
            <div className="match-ranking">
              <b>
                #
                {currentMatch.ranking_similarity}
              </b>
              {' '}
              Best Match
            </div>
            <div className="match-info">
              {currentMatch.case_study.image_link?.image && (
                <div className="image" style={{ backgroundImage: `url(${currentMatch.case_study.image_link.image})` }} />
              )}
              <div>
                <h1>
                  {currentMatch.case_study.first_name}
                </h1>
                <h2>
                  {currentMatch.case_study.occupation && (
                    <>
                      {currentMatch.case_study.occupation.job_role}
                      {' — '}
                    </>
                  )}
                  {currentMatch.match_age}
                  {' jaar oud'}
                </h2>
              </div>
            </div>
            <div className="match-buttons">
              <Button
                className="btn btn-border"
                startIcon={<TbArrowLeft />}
                onClick={handleBackToForm}
              >
                Terug naar formulier
              </Button>
              <Button
                className="btn btn-white"
                startIcon={pdfFile ? <TbFileDownload /> : <TbLoader3 className="loader" />}
                disabled={!pdfFile}
                onClick={downloadPdf}
              >
                {pdfFile ? 'PDF Downloaden' : 'Laden...'}
              </Button>
              <Button
                className="btn btn-white"
                startIcon={<TbSwitch />}
                onClick={() => setModalOpen(true)}
              >
                Bekijk alternatieven
              </Button>
            </div>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <div className="left-column">
                <div>
                  <p>
                    Dit praktijkvoorbeeld wordt aangeboden door Credit Life.
                    Wil je weten wat jouw arbeidsongeschiktheidsverzekering
                    kost? Bereken hier zelf je premie:
                    {' '}
                    <a href="https://creditlife.nl/inkomensgarantie" target="_blank" rel="noreferrer">
                      www.creditlife.nl/
                      <wbr />
                      inkomensgarantie
                    </a>
                  </p>
                  <p style={{ marginTop: 10 }}>
                    <small>
                      De personen die worden gebruikt in de
                      voorbeelden zijn met behulp van AI gegeneerd.
                    </small>
                  </p>
                  <img src="/logo-color.png" alt="Credit Life" />
                  <a
                    className="small"
                    href="https://creditlife.nl"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.creditlife.nl
                  </a>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <div className="match-info">
                {/* Additional case study details */}
                <div className="case-details">
                  <p>{currentMatch.case_study.headline}</p>
                  <p>
                    <TbMoneybag />
                    {' '}
                    {currentMatch.case_study.salary_display}
                    <br />
                    <TbUsersGroup />
                    {' '}
                    {currentMatch.match_family_status}
                  </p>
                  {currentMatch
                    && currentMatch.case_study
                    && currentMatch.case_study.occupation
                    && currentMatch.case_study.occupation.subsector
                    && currentMatch.case_study.occupation.subsector.risks && (
                      <div>
                        <h3>
                          Meest voorkomende risico&apos;s in de
                          {' '}
                          {currentMatch.case_study.occupation.subsector.nickname}
                          {' '}
                          sector
                        </h3>
                        {currentMatch.case_study.occupation.subsector.risks.map((risk, index) => (
                          <p key={index} className="risk">
                            {risk.icon ? (
                              <img
                                alt={`Icon for ${risk.name}`}
                                src={`${MEDIA_URL}${risk.icon.icon}`}
                                className="filter-color-green"
                              />
                            ) : (
                              <TbHelpHexagon />
                            )}
                            {risk.description}
                          </p>
                        ))}
                      </div>
                  )}
                  <h3>
                    Voorbeeldcase
                    {' '}
                    {currentMatch.case_study.first_name}
                  </h3>
                  {/* eslint-disable-next-line react/no-danger */}
                  <p dangerouslySetInnerHTML={{ __html: currentMatch.case_study.story }} />
                </div>
                <Button className="btn btn-border-green" startIcon={<IconZoom />} onClick={() => navigate('/client-form')} sx={{ mt: -2 }}>
                  Vind een nieuwe risicomatch
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {matches && (
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="alternatives-modal">
            <Grid container spacing={4} sx={{ justifyContent: 'center' }}>
              {matches.map((match, index) => (
                <Grid item xs={12} lg={4} key={index}>
                  {/* eslint-disable-next-line */}
                  <div
                    className="alternative-match"
                    onClick={() => handleChangeMatch(match)}
                  >
                    <div>
                      <div className="match-ranking">
                        <b>
                          #
                          {match.ranking_similarity}
                        </b>
                        {' '}
                        Best Match
                      </div>
                      {match.case_study.image_link?.image && (
                        <div className="image" style={{ backgroundImage: `url(${match.case_study.image_link.image})` }} />
                      )}
                      <h3>
                        {match.case_study.first_name}
                      </h3>
                      <h4>
                        {match.case_study.occupation && (
                          <>
                            {match.case_study.occupation.job_role}
                            {' — '}
                          </>
                        )}
                        {match.match_age}
                        {' jaar oud'}
                      </h4>
                    </div>
                    <Button
                      className="btn btn-green"
                      endIcon={<TbArrowRight />}
                      sx={{ mt: 3 }}
                    >
                      Kies deze
                    </Button>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </Modal>
      )}
    </Container>
  );
}

export default MatchesDetailView;
