import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Container } from '@mui/material';
import { TbSpy } from 'react-icons/tb';
import moment from 'moment/moment';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { TERMS_OF_USE_URL } from '../../Constants/URLS';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import LoadingScreen from '../LoadingScreen';

function TermsAndConditions() {
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  useEffect(() => {
    api.getNoAuth(`${TERMS_OF_USE_URL}latest/`).then((response) => {
      setTermsAndConditions(response.data);
    }).catch((error) => {
      if (error.response.status === 404) {
        setNotFound(true);
      }
    });
  }, []);

  if (!termsAndConditions && !notFound) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth="lg" sx={{ pt: 6, pb: 9, minHeight: '85vh' }}>
      <div className="heading">
        <TbSpy />
        <h1>Gebruikersvoorwaarden</h1>
      </div>
      {notFound && <p>Gebruikersvoorwaarden niet gevonden.</p>}
      {termsAndConditions && (
        <>
          <p style={{ marginBottom: 10 }}>
            <b>
              Laatst gewijzigd op
              {moment(termsAndConditions.created_at).format('DD-MM-YYYY')}
            </b>
          </p>
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: termsAndConditions.text }} />
        </>
      )}
    </Container>
  );
}

export default TermsAndConditions;
