import React from 'react';
import './AccessDeniedMessage.scss';
import { TbBarrierBlock } from 'react-icons/tb';
import { Container } from '@mui/material';

function AccessDeniedMessage() {
  return (
    <Container maxWidth="lg" sx={{ pt: 6, pb: 9, minHeight: 'calc(100vh - 150px)' }} className="loading-screen">
      <div>
        <TbBarrierBlock />
      </div>
      Je hebt geen toestemming om deze pagina te bekijken.
    </Container>
  );
}

export default AccessDeniedMessage;
