import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import {
  Container, Grid,
  Button, Modal, CircularProgress, Pagination,
} from '@mui/material';
import {
  TbArrowRight, TbLoader3,
  TbDeviceDesktopAnalytics,
  TbEditCircle, TbTrash, TbSend, TbFileSearch,
} from 'react-icons/tb';
import Swal2 from 'sweetalert2';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import {
  PROFESSIONS_URL,
  GENERATE_CASE_STUDY_URL,
  PROFESSION_LEVELS_URL,
  TASK_CHECKING_URL,
} from '../../Constants/URLS';
import AccessDeniedMessage from '../../Components/AccessDeniedMessage';
import LoadingScreen from '../LoadingScreen';
import './CaseStudyGenerator.scss';
import CaseStudyForm from '../../Components/CaseStudyForm/CaseStudyForm';

function CaseStudyGenerator() {
  const [prepublishedProfessions, setPrepublishedProfessions] = useState([]);
  const [generatedCases, setGeneratedCases] = useState([]);
  const [currentGender, setCurrentGender] = useState('');
  const [currentProfessionLevel, setCurrentProfessionLevel] = useState('');
  const [professionLevels, setProfessionLevels] = useState([]);
  const [selectedProfessionJobRole, setSelectedProfessionJobRole] = useState('');
  const [uniqueJobRoles, setUniqueJobRoles] = useState([]);
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const allowedRoles = ['Staff', 'Admin'];
  const hasAccess = allowedRoles.includes(userContext.user.role);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCaseStudy, setEditCaseStudy] = useState({});
  const storyEditorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingProfessions, setLoadingProfessions] = useState({});
  const [page, setPage] = useState(1);
  const [loadingPdfs, setLoadingPdfs] = useState({});
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [buttonLabels, setButtonLabels] = useState({});
  const [showGenerateButton, setShowGenerateButton] = useState(false);
  const [selectedProfessionId, setSelectedProfessionId] = useState(null);

  const handleLoadingState = (professionId, isLoading) => {
    setLoadingProfessions((prevState) => ({
      ...prevState,
      [professionId]: isLoading,
    }));
  };

  const setButtonLabel = (professionId, label) => {
    setButtonLabels((prevLabels) => ({
      ...prevLabels,
      [professionId]: label,
    }));
  };

  const rewriteStoryAndHeadline = async (caseStudyEdit) => {
    // Assuming you have caseStudy state or it can be derived from somewhere
    const postData = {
      name: caseStudyEdit.first_name,
      salary: caseStudyEdit.salary,
      gender: caseStudyEdit.gender,
      job_role: caseStudyEdit.occupation.job_role,
      disability: caseStudyEdit.disability,
    };

    try {
      const response = await api.post(`${GENERATE_CASE_STUDY_URL}rewrite-story/`, postData);
      if (response.data) {
        setEditCaseStudy((prevState) => ({
          ...prevState,
          headline: response.data.headline,
          story: response.data.story,
        }));
      } else {
        // eslint-disable-next-line no-console
        console.error('Failed to rewrite story and headline due to empty response');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error rewriting story and headline:', error);
    }
  };

  const fetchGeneratedCases = async () => {
    try {
      // Fetch the initial generated cases when the component mounts
      const response = await api.get(`${GENERATE_CASE_STUDY_URL}?all_cases=true`);
      setGeneratedCases(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Fout bij het ophalen van initiële gegenereerde gevallen:', error);
    }
  };

  const fetchUniqueJobRoles = async () => {
    try {
      const response = await api.get(`${GENERATE_CASE_STUDY_URL}unique-job-roles/?all_cases=true`);
      setUniqueJobRoles(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Fout bij het ophalen van unieke beroepsrollen:', error);
    }
  };

  const updatePrepublish = async (professionId, value) => {
    setPrepublishedProfessions(prepublishedProfessions.filter(
      (profession) => profession.id !== professionId,
    ));
    try {
      setLoading(true);
      await api.patch(`${PROFESSIONS_URL}${professionId}/`, { prepublish_list: value });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Fout bij het bijwerken van het beroep:', error);
    }
  };

  const softDelete = async (professionId) => {
    setPrepublishedProfessions(prepublishedProfessions.filter(
      (profession) => profession.id !== professionId,
    ));
    try {
      setLoading(true);
      await api.post(`${PROFESSIONS_URL}${professionId}/unpublish/`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Fout bij het bijwerken van het beroep:', error);
    }
  };

  const generateRemainingCaseStudies = async (baseCaseId, professionId) => {
    try {
      await api.post(`${GENERATE_CASE_STUDY_URL}${baseCaseId}/generate-remaining-cases/`);
      // Remove the profession from the list after cases are generated
      await updatePrepublish(professionId, false);
      setLoading(false);
      fetchGeneratedCases();
      fetchUniqueJobRoles();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to generate remaining case studies:', error);
    }
  };

  const openEditModal = (caseStudy) => {
    setShowGenerateButton(false);
    setEditCaseStudy({ ...caseStudy, story: caseStudy.story });
    setIsEditModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditCaseStudy({ ...editCaseStudy, [name]: value });
  };

  const handleEditSubmit = async (storyContent, options = {
    showAlerts: true, closeModal: true, onSuccess: null,
  }) => {
    const updatedCaseStudy = {
      ...editCaseStudy,
      story: storyContent,
    };

    if (updatedCaseStudy.occupation && updatedCaseStudy.occupation.job_role) {
      updatedCaseStudy.occupation = updatedCaseStudy.occupation.job_role;
    }

    if (updatedCaseStudy.image_link && updatedCaseStudy.image_link.id) {
      updatedCaseStudy.image_link = updatedCaseStudy.image_link.id;
    }

    if (options.closeModal) {
      setIsEditModalOpen(false);
    }

    try {
      await api.patch(`${GENERATE_CASE_STUDY_URL}${updatedCaseStudy.id}/edit-case/`, updatedCaseStudy);

      if (options.onSuccess) {
        options.onSuccess();
      }

      if (options.showAlerts) {
        Swal2.fire({
          title: 'Gelukt',
          text: 'De case is aangepast',
          icon: 'success',
        });
      }

      // Refresh the list of cases after edits
      fetchGeneratedCases();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to update case study', error);
      if (options.showAlerts) {
        Swal2.fire({
          title: 'Error',
          text: `Fout bij updaten van case: ${error.response ? error.response.data.message : error.message}`,
          icon: 'error',
        });
      }
    }
  };

  const handleGenerate = async (caseStudyId, professionId, options = {
    showAlerts: true, generateAll: true,
  }) => {
    setIsEditModalOpen(false);
    try {
      const { data } = await api.get(`${GENERATE_CASE_STUDY_URL}${caseStudyId}/check-need-for-generation/`);

      if (data.needGeneration && options.generateAll) {
        if (options.showAlerts) {
          Swal2.fire({
            title: 'Even geduld a.u.b.',
            text: 'De overige cases worden gegenereerd...',
            didOpen: () => {
              Swal2.showLoading();
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          });
        }

        await generateRemainingCaseStudies(caseStudyId, professionId);

        if (options.showAlerts) {
          Swal2.fire({
            title: 'Gelukt',
            text: 'Alle resterende cases voor de beroep zijn geüpload',
            icon: 'success',
          });
        }
      } else if (options.showAlerts) {
        Swal2.fire({
          title: 'Gelukt',
          text: 'Er is geen verdere generatie nodig',
          icon: 'success',
        });
      }

      // Refresh the list of cases after generation
      fetchGeneratedCases();
      // Reset professionId after successful generation
      setSelectedProfessionId(null);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to generate remaining case studies', error);
      if (options.showAlerts) {
        Swal2.fire({
          title: 'Error',
          text: `Fout bij het genereren van resterende cases: ${error.response ? error.response.data.message : error.message}`,
          icon: 'error',
        });
      }
    }
  };

  const fetchProfessionLevels = async () => {
    try {
      const response = await api.get(PROFESSION_LEVELS_URL);
      setProfessionLevels(response.data.levels);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Fout bij het ophalen van beroepsniveaus:', error);
    }
  };

  const fetchProfessions = async () => {
    if (!hasAccess) return;
    setLoading(true);
    try {
      const response = await api.get(`${PROFESSIONS_URL}?prepublish_list=true`);
      setPrepublishedProfessions(response.data);
      // Update button labels based on the is_ready_for_viewing property
      const newButtonLabels = response.data.reduce((acc, profession) => ({
        ...acc,
        [profession.id]: profession.is_ready_for_viewing ? 'Bekijken' : 'Genereer',
      }), {});
      setButtonLabels(newButtonLabels);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Fout bij ophalen van beroepen:', error);
      setLoading(false);
    }
  };

  const handleViewButtonClick = async (professionId) => {
    try {
      const url = `${GENERATE_CASE_STUDY_URL}${professionId}/junior-man-case/`;
      const response = await api.get(url);
      if (response.data) {
        setSelectedProfessionId(professionId);
        setShowGenerateButton(true);
        setEditCaseStudy({ ...response.data, story: response.data.story });
        setIsEditModalOpen(true);
      } else {
        // eslint-disable-next-line no-console
        console.error('No case study data returned:', response);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch new case study details:', error);
    }
  };

  const checkTaskStatus = (taskId, professionId) => {
    const interval = setInterval(async () => {
      try {
        const response = await api.get(`${TASK_CHECKING_URL}${taskId}/`);
        if (response.data.status === 'SUCCESS') {
          clearInterval(interval);
          setButtonLabel(professionId, 'Bekijken');
          handleLoadingState(professionId, false);
        } else if (response.data.status !== 'PENDING') {
          clearInterval(interval);
          // eslint-disable-next-line no-console
          console.error('Task failed or unknown status:', response.data);
        }
      } catch (error) {
        clearInterval(interval);
        // eslint-disable-next-line no-console
        console.error('Failed to fetch task status:', error);
      }
    }, 2000); // Poll every 2 seconds
  };

  const generatePrePublishedCaseStudies = async (professionId) => {
    handleLoadingState(professionId, true);
    try {
      // Triggering the Celery task via backend
      const response = await api.post(`${GENERATE_CASE_STUDY_URL}${professionId}/generate-case-study/`);
      const taskId = response.data.task_id; // Expecting task_id from the backend

      if (taskId) {
        // Store taskId to check status later, or you can directly invoke status checking
        checkTaskStatus(taskId, professionId);
      } else {
        // eslint-disable-next-line no-console
        console.error('Failed to initiate Celery task');
      }
    } catch (error) {
      Swal2.fire({
        title: 'Oeps...',
        text: 'Fout bij genereren of ophalen van vooraf gepubliceerde casestudies.',
        icon: 'error',
      });
      // eslint-disable-next-line no-console
      console.error('Error:', error);
    }
  };

  const publishAllFilteredCases = async () => {
    // Filter cases based on your existing logic, same as how they're displayed
    const filteredCases = generatedCases.filter((caseStudy) => {
      const matchesGender = currentGender
        ? caseStudy.gender === currentGender : true;
      const matchesProfessionLevel = currentProfessionLevel
        ? caseStudy.profession_level === currentProfessionLevel : true;
      const matchesJobRole = selectedProfessionJobRole
        ? caseStudy.occupation.job_role === selectedProfessionJobRole : true;
      return matchesGender && matchesProfessionLevel && matchesJobRole;
    });

    // Map each case to a publish request
    const publishPromises = filteredCases.map((caseStudy) => (
      api.post(`${GENERATE_CASE_STUDY_URL}${caseStudy.id}/publish/`)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(`Failed to publish case study ${caseStudy.id}`, error);
          return Promise.reject(error); // Reject the promise to ensure Promise.all catches it
        })
    ));

    Promise.all(publishPromises)
      .then(() => {
        // This block runs if all publish requests succeeded
        Swal2.fire({
          title: 'Gelukt',
          text: 'Alle weergegeven cases zijn succesvol gepubliceerd.',
          icon: 'success',
        });
        fetchGeneratedCases();
        fetchUniqueJobRoles();
      })
      .catch(() => {
        // This block runs if any publish request failed
        Swal2.fire({
          title: 'Oeps...',
          text: 'Het is niet gelukt om een of meer cases te publiceren.',
          icon: 'error',
        });
      });
  };

  const deleteAllFilteredCases = async () => {
    const filteredCases = generatedCases.filter((caseStudy) => {
      const matchesGender = currentGender
        ? caseStudy.gender === currentGender : true;
      const matchesProfessionLevel = currentProfessionLevel
        ? caseStudy.profession_level === currentProfessionLevel : true;
      const matchesJobRole = selectedProfessionJobRole
        ? caseStudy.occupation.job_role === selectedProfessionJobRole : true;
      return matchesGender && matchesProfessionLevel && matchesJobRole;
    });

    const deletePromises = filteredCases.map(
      (caseStudy) => api.del(`${GENERATE_CASE_STUDY_URL}${caseStudy.id}/`)
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(`Failed to delete case study ${caseStudy.id}`, error);
          return Promise.reject(error);
        }),
    );

    Promise.all(deletePromises)
      .then(() => {
        Swal2.fire({
          title: 'Gelukt',
          text: 'Alle weergegeven cases zijn succesvol verwijderd.',
          icon: 'success',
        });
        fetchGeneratedCases();
        fetchUniqueJobRoles();
      })
      .catch(() => {
        Swal2.fire({
          title: 'Oeps...',
          text: 'Het is niet gelukt om een of meer cases te verwijderen.',
          icon: 'error',
        });
      });
  };

  const downloadPdf = async (caseStudyId) => {
    setLoadingPdfs((prev) => ({ ...prev, [caseStudyId]: true }));
    try {
      const response = await api.get(`${GENERATE_CASE_STUDY_URL}${caseStudyId}/download-pdf`, {
        responseType: 'blob',
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        setPdfBlob(URL.createObjectURL(blob));
        setPdfModalOpen(true);
      } else {
        // eslint-disable-next-line no-console
        console.error('Failed to download PDF');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error downloading PDF:', error);
    } finally {
      setLoadingPdfs((prev) => ({ ...prev, [caseStudyId]: false }));
    }
  };

  const handlePreviewPdf = async (caseStudyId, localStory) => {
    setLoadingPdfs((prev) => ({ ...prev, [caseStudyId]: true }));
    handleEditSubmit(localStory, {
      showAlerts: false,
      closeModal: false,
      onSuccess: async () => {
        try {
          const response = await api.get(`${GENERATE_CASE_STUDY_URL}${caseStudyId}/download-pdf`, {
            responseType: 'blob',
          });

          if (response.status === 200) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            setPdfBlob(URL.createObjectURL(blob));
            setPdfModalOpen(true);
          } else {
            // eslint-disable-next-line no-console
            console.error('Failed to preview PDF');
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error previewing PDF:', error);
        } finally {
          setLoadingPdfs((prev) => ({ ...prev, [caseStudyId]: false }));
        }
      },
    });
  };

  useEffect(() => {
    fetchProfessions();
    fetchProfessionLevels();
    fetchUniqueJobRoles();
    fetchGeneratedCases();
  }, [hasAccess]);

  if (!userContext.user || !userContext.user.id) {
    return <LoadingScreen />;
  }

  if (!hasAccess) {
    return <AccessDeniedMessage />;
  }

  return (
    <div className="page-container">
      <Container maxWidth="lg" sx={{ mt: 6, mb: 9 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <div className="dashboard-panel compact">
              <div className="panel-header">
                <h2>Doorgestuurde Beroepenlijst</h2>
              </div>
              <div className="panel-content" style={{ padding: 15 }}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    {prepublishedProfessions.map((profession) => (
                      <div key={profession.id} className="profession-list-item">
                        <div className="profession-name">{profession.job_role}</div>
                        <div className="buttons-container">
                          {/* Delete button */}
                          <Button
                            onClick={async () => {
                              setLoading(true);
                              await softDelete(profession.id);
                              setLoading(false);
                            }}
                            className="btn btn-small btn-icon btn-red"
                          >
                            <TbTrash />
                          </Button>
                          <Button
                            onClick={() => {
                              if (buttonLabels[profession.id] === 'Bekijken') {
                                handleViewButtonClick(profession.id);
                              } else {
                                generatePrePublishedCaseStudies(profession.id);
                              }
                            }}
                            className={`btn btn-small ${buttonLabels[profession.id] === 'Bekijken' ? 'btn-green' : 'btn-grey'}`}
                            sx={{ width: 'fit-content' }}
                            disabled={loadingProfessions[profession.id] || false}
                            endIcon={
                              loadingProfessions[profession.id] ? (
                                <TbLoader3 size={20} className="loader" />
                              ) : (
                                <TbArrowRight />
                              )
                            }
                          >
                            {buttonLabels[profession.id] || 'Genereer'}
                          </Button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <div className="heading">
              <TbDeviceDesktopAnalytics />
              <h1>Gegenereerde Cases</h1>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <label>Filteren op Geslacht</label>
                <select
                  value={currentGender}
                  onChange={(e) => setCurrentGender(e.target.value)}
                >
                  <option value="">Geen</option>
                  <option value="Man">Man</option>
                  <option value="Vrouw">Vrouw</option>
                </select>
              </Grid>
              <Grid item xs={12} lg={4}>
                <label>Filteren op Beroepsniveau</label>
                <select
                  value={currentProfessionLevel}
                  onChange={(e) => setCurrentProfessionLevel(e.target.value)}
                >
                  <option value="">Geen</option>
                  {professionLevels.map(([value, label]) => (
                    <option key={value} value={value}>{label}</option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={12} lg={4}>
                <label>Filteren op Beroep</label>
                <select
                  value={selectedProfessionJobRole}
                  onChange={(e) => setSelectedProfessionJobRole(e.target.value)}
                >
                  <option value="">Geen</option>
                  {uniqueJobRoles.map((jobRole, index) => (
                    <option
                      key={index}
                      value={jobRole}
                    >
                      {jobRole}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Button
                  onClick={publishAllFilteredCases}
                  className="btn btn-green btn-full-width"
                  startIcon={<TbSend />}
                >
                  Publiceer alle weergegeven cases
                </Button>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Button
                  onClick={deleteAllFilteredCases}
                  className="btn btn-red btn-full-width"
                  startIcon={<TbTrash />}
                >
                  Verwijderen alle weergegeven cases
                </Button>
              </Grid>
            </Grid>
            {generatedCases
              .filter((caseStudy) => {
                const matchesGender = currentGender
                  ? caseStudy.gender === currentGender
                  : true;
                const matchesProfessionLevel = currentProfessionLevel
                  ? caseStudy.profession_level === currentProfessionLevel
                  : true;
                const matchesJobRole = selectedProfessionJobRole
                  ? caseStudy.occupation.job_role === selectedProfessionJobRole
                  : true;

                return matchesGender && matchesProfessionLevel && matchesJobRole;
              })
              .slice((page - 1) * 5, page * 5)
              .map((caseStudy, index) => (
                <div
                  key={index}
                  className="panel generated-case"
                >
                  <div className="case-header">
                    <div className="case-info">
                      <div className="circle-image">
                        <img
                          src={caseStudy.image_link ? caseStudy.image_link.image : '/no-avatar.jpg'}
                          alt={caseStudy.image_link ? caseStudy.image_link.short_description : 'Placeholder'}
                        />
                      </div>
                      <div className="case-heading">
                        <h3>
                          {caseStudy.first_name}
                          {' - '}
                          {caseStudy.profession_level}
                          {': '}
                          {caseStudy.occupation.job_role}
                        </h3>
                        <span>
                          Geslacht:
                          {' '}
                          {caseStudy.gender}
                        </span>
                      </div>
                    </div>
                    <div>
                      <Button
                        className="btn btn-green btn-small"
                        startIcon={loadingPdfs[caseStudy.id] ? <TbLoader3 className="loader" /> : <TbFileSearch />}
                        disabled={loadingPdfs[caseStudy.id]}
                        onClick={() => downloadPdf(caseStudy.id)}
                        sx={{ mr: 2 }}
                      >
                        {loadingPdfs[caseStudy.id] ? 'Laden...' : 'PDF'}
                      </Button>
                      <Button
                        className="btn btn-white btn-small"
                        startIcon={<TbEditCircle />}
                        onClick={() => openEditModal(caseStudy)}
                      >
                        Aanpassen
                      </Button>
                    </div>
                  </div>
                  <div className="case-content">
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}>
                        <b>
                          Salaris:
                          {' '}
                        </b>
                        {caseStudy.salary_display}
                        <br />
                        <br />
                        <b>
                          Opschrift:
                          {' '}
                        </b>
                        {caseStudy.headline}
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <b>
                          Voorbeeldcase:
                        </b>
                        {/* eslint-disable-next-line react/no-danger */}
                        <p dangerouslySetInnerHTML={{ __html: caseStudy.story }} />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ))}
            <div className="pagination">
              <Pagination
                count={
                  Math.ceil(
                    generatedCases
                      .filter((caseStudy) => {
                        const matchesGender = currentGender
                          ? caseStudy.gender === currentGender
                          : true;
                        const matchesProfessionLevel = currentProfessionLevel
                          ? caseStudy.profession_level === currentProfessionLevel
                          : true;
                        const matchesJobRole = selectedProfessionJobRole
                          ? caseStudy.occupation.job_role === selectedProfessionJobRole
                          : true;

                        return matchesGender && matchesProfessionLevel && matchesJobRole;
                      }).length / 5,
                  )
                }
                page={page}
                onChange={(_e, value) => setPage(value)}
                boundaryCount={1}
                sx={{ mt: 3 }}
              />
            </div>
          </Grid>
        </Grid>
        <Modal
          open={pdfModalOpen}
          onClose={() => setPdfModalOpen(false)}
          aria-labelledby="pdf-modal"
        >
          <div className="modal-content">
            <iframe src={pdfBlob} width={600} height={600} title="PDF" />
          </div>
        </Modal>
        {/* Modal for Editing Case Study */}
        <Modal
          open={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          aria-labelledby="edit-case-study-modal"
        >
          <div className="modal-content" key={editCaseStudy ? editCaseStudy.id : 'new'}>
            {editCaseStudy && (
              <CaseStudyForm
                newCaseStudy={editCaseStudy}
                handleInputChange={handleInputChange}
                handleSubmit={handleEditSubmit}
                story={editCaseStudy.story}
                storyEditorRef={storyEditorRef}
                handlePreviewPdf={handlePreviewPdf}
                rewriteStoryAndHeadline={rewriteStoryAndHeadline}
                showGenerateCasesButton={showGenerateButton}
                handleGenerateCases={handleGenerate}
                professionId={selectedProfessionId}
              />
            )}
          </div>
        </Modal>
      </Container>
    </div>
  );
}

export default CaseStudyGenerator;
