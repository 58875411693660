import React, { useState, useEffect, useContext } from 'react';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination,
  TableSortLabel, Container, Chip,
} from '@mui/material';
import { TbMessages } from 'react-icons/tb';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { CONVERSATION_TABLE_VIEW } from '../../Constants/URLS';
import {
  containerStyles, tablePaginationStyles,
} from '../../Components/TableStyles/TableStyles';
import './ConversationTableView.scss';

function ConversationTableView() {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [conversations, setConversations] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [search, setSearch] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const fetchConversations = async () => {
    const response = await api.get(CONVERSATION_TABLE_VIEW, {
      params: {
        page,
        page_size: rowsPerPage,
        ordering: `${order === 'desc' ? '-' : ''}${orderBy}`,
        search,
        test: 'false',
      },
    });
    setConversations(response.data.results);
    setTotalCount(response.data.count);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    fetchConversations();
  }, [page, rowsPerPage, order, orderBy, search]);

  return (
    <Container maxWidth="lg" sx={containerStyles}>
      <div className="heading">
        <TbMessages />
        <h1>Gesprekken</h1>
      </div>
      <div className="conversations-view">
        <div className="backoffice-filters-search-bar">
          <input
            placeholder="Zoek..."
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
              setPage(1);
            }}
          />
        </div>
        <TableContainer>
          <Table>
            <TableHead className="table-header">
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'user__email'}
                    direction={orderBy === 'user__email' ? order : 'asc'}
                    onClick={() => handleRequestSort('user__email')}
                  >
                    Gebruiker email
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'user__full_name'}
                    direction={orderBy === 'user__full_name' ? order : 'asc'}
                    onClick={() => handleRequestSort('user__full_name')}
                  >
                    Gebruiker volledige naam
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Onderwerpen
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'created_at'}
                    direction={orderBy === 'created_at' ? order : 'asc'}
                    onClick={() => handleRequestSort('created_at')}
                  >
                    Gemaakt op
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'last_accessed'}
                    direction={orderBy === 'last_accessed' ? order : 'asc'}
                    onClick={() => handleRequestSort('last_accessed')}
                  >
                    Laatst geopend
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {conversations.map((conversation) => (
                <TableRow
                  key={conversation.id}
                  className="table-row"
                >
                  <TableCell className="table-cell">{conversation.user.email}</TableCell>
                  <TableCell className="table-cell">{conversation.user.full_name_infix}</TableCell>
                  <TableCell className="table-cell">
                    {conversation.topics.map((topic, index) => (
                      <Chip
                        className="status-chip"
                        key={topic.id}
                        label={topic.name}
                        size="small"
                        sx={{
                          color: '#606060 !important',
                          mr: index === conversation.topics.length - 1 ? 0 : 1,
                        }}
                      />
                    ))}
                  </TableCell>
                  <TableCell className="table-cell">
                    {new Date(conversation.created_at).toLocaleString([], {
                      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',
                    })}
                  </TableCell>
                  <TableCell className="table-cell">
                    {new Date(conversation.last_accessed).toLocaleString([], {
                      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          page={page - 1}
          onPageChange={(event, newPage) => setPage(newPage + 1)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(1);
          }}
          labelRowsPerPage="Rijen per pagina:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count !== -1 ? count : `meer dan ${to}`}`}
          style={tablePaginationStyles}
        />
      </div>
    </Container>
  );
}

export default ConversationTableView;
