export const containerStyles = {
  mt: 6,
  mb: 9,
};

export const textFieldStyles = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
};

export const selectStyles = {
  marginLeft: '16px',
  minWidth: '160px',
  backgroundColor: '#EFEFEF',
  borderRadius: '50px',
  fontFamily: 'Frutiger, sans-serif',
  '& .MuiSelect-select': {
    padding: '10px 20px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

export const buttonStyles = {
  marginLeft: '16px',
};

export const tablePaginationStyles = {
  marginTop: '16px',
  marginBottom: '6px',
};

export const iconButtonStyles = {
  marginLeft: '8px',
  backgroundColor: '#EFEFEF',
  color: '#606060',
  alignSelf: 'center',
  '&:hover': {
    backgroundColor: '#BAD60A',
    color: '#fff',
  },
};

export const collapseContainerStyles = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '1.5em',
  alignSelf: 'center',
};
