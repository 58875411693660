import React, {
  useState, useContext, useEffect,
} from 'react';
import {
  Container, Button, Box, IconButton, Grid, Tooltip,
} from '@mui/material';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useNavigate, useParams } from 'react-router-dom';
import Swal2 from 'sweetalert2';
import {
  TbTrash, TbPlus, TbLoader3, TbX,
} from 'react-icons/tb';
import { useDropzone } from 'react-dropzone';
import { IconCircleDashedCheck, IconHelpOctagon, IconTrash } from '@tabler/icons-react';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { FREQUENTLY_ASKED_QUESTIONS_URL, TOPICS_LIGHT_URL, SOURCES_URL } from '../../../../Constants/URLS';
import { containerStyles } from '../../../../Components/TableStyles/TableStyles';
import ModerationComponent from '../../../../Components/ModerationComponent';
import topicsStyles from '../../topicStyles';
import sourceIcon from '../../../../Utils/SourceUtils';
import TestChat from '../../../../Components/Chat/TestChat';

function FAQEditView() {
  const { id } = useParams();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const navigate = useNavigate();

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [featured, setFeatured] = useState(false);
  const [originalSources, setOriginalSources] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [testOpen, setTestOpen] = useState(false);

  const {
    acceptedFiles, getRootProps, getInputProps, inputRef,
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'text/plain': ['.txt'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    maxFiles: 1,
  });

  const fetchFAQAndTopics = async () => {
    try {
      // Fetch the FAQ details by ID
      const faqResponse = await api.get(`${FREQUENTLY_ASKED_QUESTIONS_URL}${id}/`);

      // Fetch the full list of topics
      const topicsResponse = await api.get(TOPICS_LIGHT_URL);

      // Match selected topics by ID to get the full topic details (value and label)
      const matchedTopics = faqResponse.data.topics.map((topicId) => {
        const topic = topicsResponse.data.find((t) => t.id === topicId);
        return {
          value: topic.id,
          label: topic.name,
        };
      });

      // Set form fields with fetched data
      setQuestion(faqResponse.data.question);
      setAnswer(faqResponse.data.answer);
      setFeatured(faqResponse.data.featured);
      setSelectedTopics(matchedTopics);
      setOriginalSources(faqResponse.data.source_details);
    } catch (error) {
      Swal2.fire({
        title: 'Fout',
        text: `Kan het FAQ niet ophalen: ${error.response ? error.response.data.message : error.message}`,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    fetchFAQAndTopics();
  }, [id]);

  const handleSelectTopics = (selectedOptions) => {
    setSelectedTopics(selectedOptions || []);
  };

  const loadTopics = async (search) => {
    const response = await api.get(`${TOPICS_LIGHT_URL}?search=${search}`);
    return {
      options: response.data.map((topic) => ({
        value: topic.id,
        label: topic.name,
      })),
      hasMore: false,
    };
  };

  const uploadSources = async () => {
    if (acceptedFiles.length === 0) {
      return [];
    }

    const uploadPromises = acceptedFiles.map((file) => {
      const formData = new FormData();
      formData.append('source_file', file);

      return api.post(SOURCES_URL, formData);
    });

    try {
      const responses = await Promise.all(uploadPromises);
      // clear the dropzone
      inputRef.current.value = '';
      return responses
        .filter((response) => response.status === 201)
        .map((response) => response.data.id);
    } catch (error) {
      Swal2.fire({
        title: 'Fout',
        text: `Er is een fout opgetreden bij het uploaden van de bron: ${error.response ? error.response.data.message : error.message}`,
        icon: 'error',
      });
      return [];
    }
  };

  const updateFAQ = async () => {
    setIsLoading(true);
    try {
      const uploadedSourceIds = await uploadSources();
      const sourceIds = [
        ...originalSources.map((source) => source.id),
        ...uploadedSourceIds,
      ];
      const response = await api.patch(`${FREQUENTLY_ASKED_QUESTIONS_URL}${id}/`, {
        question,
        answer,
        topics: selectedTopics.map((topic) => topic.value),
        sources: sourceIds,
        featured,
      });

      if (response.status === 200) {
        Swal2.fire({
          title: 'Succesvol',
          text: 'Het veelgestelde vraag is bijgewerkt.',
          icon: 'success',
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      Swal2.fire({
        title: 'Fout',
        text: `Er is een fout opgetreden bij het bijwerken van het veelgestelde vraag: ${error.response ? error.response.data.message : error.message}`,
        icon: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteFAQ = async () => {
    Swal2.fire({
      title: 'Weet je het zeker?',
      text: 'Dit kan niet ongedaan worden gemaakt!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, verwijder het!',
      cancelButtonText: 'Annuleren',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.del(`${FREQUENTLY_ASKED_QUESTIONS_URL}${id}`);
          Swal2.fire(
            'Verwijderd!',
            'Het veelgesteedle vraag is verwijderd.',
            'success',
          );
          navigate('/backoffice/faqs');
        } catch (error) {
          Swal2.fire({
            title: 'Fout',
            text: `Kan het veelgestelde vraag niet verwijderen: ${error.response ? error.response.data.message : error.message}`,
            icon: 'error',
          });
        }
      }
    });
  };

  const handleDeleteSource = (sourceId) => {
    Swal2.fire({
      title: 'Weet je het zeker?',
      text: 'Dit kan niet ongedaan worden gemaakt!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, verwijder het!',
      cancelButtonText: 'Annuleren',
    }).then((result) => {
      if (result.isConfirmed) {
        setOriginalSources((prevSources) => prevSources.filter((s) => s.id !== sourceId));
        Swal2.fire(
          'Verwijderd!',
          'De bron is verwijderd.',
          'success',
        );
      }
    });
  };

  useEffect(() => {
    setIsFormValid(question.trim().length > 0 && answer.trim().length > 0);
  }, [question, answer]);

  return (
    <Container maxWidth="lg" sx={containerStyles}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className="heading" style={{ marginBottom: 0 }}>
          <IconHelpOctagon />
          <h1>Bewerk veelgestelde vraag</h1>
        </div>
        <div>
          <Button
            className="btn btn-green"
            startIcon={<IconCircleDashedCheck />}
            sx={{ mr: 1 }}
            onClick={() => setTestOpen(true)}
          >
            Test Vraag
          </Button>
          <Button
            className="btn btn-red"
            startIcon={<TbTrash />}
            onClick={deleteFAQ}
          >
            Verwijder
          </Button>
        </div>
        <TestChat
          title="Test Gesprek"
          open={testOpen}
          close={() => setTestOpen(false)}
        />
      </Box>
      <div className="chat-backoffice-view">
        <h4>Vraag</h4>
        <input
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Typ hier je vraag..."
        />
        <h4>Antwoord</h4>
        <textarea
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          placeholder="Typ hier je antwoord..."
          rows={4}
        />
        <h4>Onderwerpen</h4>
        <AsyncPaginate
          loadOptions={loadTopics}
          onChange={handleSelectTopics}
          isSearchable
          isClearable
          additional={{}}
          isMulti
          debounceTimeout={300}
          placeholder="Selecteer onderwerpen..."
          value={selectedTopics}
          styles={topicsStyles}
        />
        <h4>Bronnen</h4>
        <label>Huidig bestanden:</label>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          {originalSources.length > 0 ? (
            <>
              {originalSources.map((source) => (
                <Grid item xs={12} lg={2} key={source.id}>
                  <div className="current-file-container">
                    <Tooltip title={source.source_file.split('/').pop()} arrow placement="top">
                      {/* eslint-disable-next-line */}
                      <div className="current-file" onClick={() => window.open(source.source_file)}>
                        <img src={sourceIcon(source)} alt="source-icon" />
                        {source.source_file.split('/').pop().slice(0, 17)}
                        ...
                      </div>
                    </Tooltip>
                    <Tooltip title="Huidige bron verwijderen" arrow placement="top">
                      <IconButton
                        className="btn-delete"
                        onClick={() => handleDeleteSource(source.id)}
                      >
                        <TbX />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              ))}
            </>
          ) : (
            <Grid item xs={12} lg={2}>
              <div className="current-file">
                Geen bestand
              </div>
            </Grid>
          )}
        </Grid>
        <label>Nieuw bestand:</label>
        {/* eslint-disable-next-line */}
        <div {...getRootProps({className: 'dropzone'})}>
          {/* eslint-disable-next-line */}
          <input {...getInputProps()} />
          {acceptedFiles.length > 0 ? (
            <Tooltip title="Verwijder bestand" arrow placement="top">
              <div className="dropzone-file">
                <img src={sourceIcon({ source_file: acceptedFiles[0].path })} alt="source-icon" />
                {acceptedFiles[0].path}
                <div className="overlay">
                  <IconTrash />
                </div>
              </div>
            </Tooltip>
          ) : (
            <p>Drag & drop het bestand hierheen of klik om een bestand te selecteren.</p>
          )}
        </div>
        <ModerationComponent
          modelType="frequentlyaskedquestion"
          userContext={userContext}
          useFeatured
          featured={featured}
          setFeatured={setFeatured}
        />
        <Button
          startIcon={isLoading ? <TbLoader3 size={20} className="loader" /> : <TbPlus />}
          className="btn btn-green"
          onClick={updateFAQ}
          disabled={!isFormValid || isLoading}
          sx={{ mt: 3 }}
        >
          Bijwerken
        </Button>
      </div>
    </Container>
  );
}

export default FAQEditView;
