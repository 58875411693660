const topicsStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '5px',
    border: 'none !important',
    outline: 'none !important',
    background: 'rgba(0, 0, 0, 0.05)',
    boxShadow: 'none !important',
    fontFamily: 'Frutiger, sans-serif',
    fontSize: '14px !important',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '15px !important',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0 !important',
    padding: '0 !important',
  }),
};

export default topicsStyles;
