import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination,
  TableSortLabel, Container,
  Button, Grid,
} from '@mui/material';
import { TbBook, TbPlus } from 'react-icons/tb';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { SOURCES_TABLE_URL } from '../../../../Constants/URLS';
import StatusChip from '../../../../Components/StatusChip';
import {
  containerStyles, tablePaginationStyles,
} from '../../../../Components/TableStyles/TableStyles';
import './SourcesTableView.scss';

function SourcesTableView() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [sources, setSources] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const fetchSources = async () => {
    const response = await api.get(SOURCES_TABLE_URL, {
      params: {
        page,
        page_size: rowsPerPage,
        ordering: `${order === 'desc' ? '-' : ''}${orderBy}`,
        search,
        status,
      },
    });
    setSources(response.data.results);
    setTotalCount(response.data.count);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    fetchSources();
  }, [page, rowsPerPage, order, orderBy, search, status]);

  return (
    <Container maxWidth="lg" sx={containerStyles}>
      <div className="heading">
        <TbBook />
        <h1>Chat Bronnen</h1>
      </div>
      <div className="sources-table-view">
        <Grid container spacing={2} className="backoffice-filters-search-bar">
          <Grid item xs={12} lg={8}>
            <input
              placeholder="Zoek..."
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
                setPage(1);
              }}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <select value={status} onChange={(event) => setStatus(event.target.value)}>
              <option value="">Alle statussen</option>
              <option value="Live">Live</option>
              <option value="Offline">Offline</option>
              <option value="Eerste check">Eerste check</option>
              <option value="Tweede check">Tweede check</option>
            </select>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Button
              onClick={() => navigate('/backoffice/sources/add')}
              startIcon={<TbPlus />}
              className="btn btn-green"
              fullWidth
            >
              Nieuwe bron
            </Button>
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead className="table-header">
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'id'}
                    direction={orderBy === 'id' ? order : 'asc'}
                    onClick={() => handleRequestSort('id')}
                  >
                    ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'title'}
                    direction={orderBy === 'title' ? order : 'asc'}
                    onClick={() => handleRequestSort('title')}
                  >
                    Titel/ Bestandsnaam
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'topics_names'}
                    direction={orderBy === 'topics_names' ? order : 'asc'}
                    onClick={() => handleRequestSort('topics_names')}
                  >
                    Onderwerpen
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sources.map((source) => (
                <TableRow
                  key={source.id}
                  className="table-row"
                  hover
                  onClick={() => navigate(`/backoffice/sources/edit/${source.id}`)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell className="table-cell">{`#${source.id}`}</TableCell>
                  <TableCell className="table-cell">{source.title}</TableCell>
                  <TableCell className="table-cell">
                    {source.topics.join(', ')}
                  </TableCell>
                  <TableCell className="table-cell">
                    <StatusChip chipStatus={source.status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          page={page - 1}
          onPageChange={(event, newPage) => setPage(newPage + 1)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(1);
          }}
          labelRowsPerPage="Rijen per pagina:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count !== -1 ? count : `meer dan ${to}`}`}
          style={tablePaginationStyles}
        />
      </div>
    </Container>
  );
}

export default SourcesTableView;
