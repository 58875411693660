export default function sourceIcon(source) {
  const extension = source.source_file.split('.').pop();

  switch (extension) {
    case 'pdf':
      return '/source-pdf.png';
    case 'doc':
      return '/source-doc.png';
    case 'docx':
      return '/source-doc.png';
    default:
      return '/source-txt.png';
  }
}
