export default function loginReducer(state, action) {
  switch (action.type) {
    case 'USERNAME':
      return { ...state, username: action.payload };
    case 'PASSWORD':
      return { ...state, password: action.payload };
    case 'VALIDATE': {
      const usernameError = state.username.length === 0 ? 'Moet een gebruikersnaam opgeven' : '';
      const passwordError = state.password.length === 0 ? 'Moet een wachtwoord opgeven' : '';

      return {
        ...state,
        usernameError,
        passwordError,
        canSubmit: !usernameError && !passwordError,
        fullFieldError: '',
      };
    }
    case 'SUBMIT':
      return { ...state, canSubmit: false, submitting: action.payload };
    case 'HANDLE_ERROR': {
      const errors = {};
      // django naming... makes this a bit uncomfortable
      const nonFieldErrors = action.payload.non_field_errors;
      if (nonFieldErrors) {
        [errors.fullFieldError] = nonFieldErrors;
      }

      return { ...state, ...errors };
    }
    default:
      throw new Error(`${action.type}unknown`);
  }
}
