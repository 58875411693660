import React, {
  useState, useEffect, useContext,
} from 'react';
import {
  Container, Grid, Button,
} from '@mui/material';
import { FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './ProfileSettingsView.scss';
import {
  TbDeviceFloppy, TbLockCheck, TbLockSquareRounded, TbUserSquareRounded, TbTrash,
} from 'react-icons/tb';
import Swal2 from 'sweetalert2';
import UserProfileApi from '../../APIs/UserProfileApi';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import logUserAction from '../../Components/LogUserActions/LogUserActions';
import apiUtils from '../../Utils/ApiUtils';

function ProfileSettingsView() {
  const userContext = useContext(UserContext);
  const UserAPIs = UserProfileApi(userContext);
  const { user, logout } = userContext;
  const navigate = useNavigate();
  const api = apiUtils();

  const [userData, setUserData] = useState({
    firstName: '',
    infix: '',
    surname: '',
    positionRole: '',
    phoneNumber: '',
    email: '',
    officeName: '',
  });

  const [passwordData, setPasswordData] = useState({
    newPassword: '',
    confirmNewPassword: '',
  });

  const [jobRoles, setJobRoles] = useState([]);

  const handleDeleteAccount = () => {
    Swal2.fire({
      title: 'Weet je zeker dat je je account wilt verwijderen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ja, verwijder mijn account',
      cancelButtonText: 'Nee, verwijder mijn account niet',
    }).then((result) => {
      if (result.isConfirmed) {
        UserAPIs.deleteAccount(user.id)
          .then(() => {
            Swal2.fire(
              'Verwijderd!',
              'Je account is verwijderd.',
              'success',
            );
            logUserAction(user.id, 'Logout', api);
            logout();
            navigate('/');
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error deleting user:', error);
          });
      }
    });
  };

  useEffect(() => {
    // Fetch job roles from the backend
    UserAPIs.getJobRoles()
      .then((response) => {
        setJobRoles(response.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching job roles', error);
      });
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await UserAPIs.getUserDetails();
        const {
          first_name: firstName = '',
          infix = '',
          last_name: lastName = '',
          position_role: positionRole = '',
          phone_number: phoneNumber = '',
          email: emailAddress = '',
          office_name: officeName = '',
        } = response.data;

        setUserData({
          firstName,
          infix,
          surname: lastName,
          positionRole,
          phoneNumber,
          email: emailAddress,
          officeName,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching user details', error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleUserInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handlePasswordInputChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const validatePhoneNumber = (phoneNumber) => {
    const strippedPhoneNumber = phoneNumber.replace(/\s+/g, '');
    if (strippedPhoneNumber.startsWith('+31') && strippedPhoneNumber.length === 12) {
      return strippedPhoneNumber;
    }
    if (strippedPhoneNumber.startsWith('0') && strippedPhoneNumber.length === 10) {
      return `+31${strippedPhoneNumber.slice(1)}`;
    }
    return null;
  };

  const handleSubmitPersonalDetails = async (e) => {
    e.preventDefault();
    try {
      const validatedPhoneNumber = validatePhoneNumber(userData.phoneNumber);
      if (userData.phoneNumber && !validatedPhoneNumber) {
        Swal2.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ongeldig telefoonnummer',
        });
        return;
      }
      const updateData = {
        first_name: userData.firstName,
        infix: userData.infix,
        last_name: userData.surname,
        position_role: userData.positionRole,
        phone_number: validatedPhoneNumber || '',
        office_name: userData.officeName,
      };
      await UserAPIs.updateUserDetails(updateData);
      Swal2.fire({
        icon: 'success',
        title: 'Gelukt',
        text: 'Gebruikersgegevens zijn bijgewerkt',
      }).then(() => {
        window.location.reload(false);
      });
    } catch (error) {
      Swal2.fire({
        icon: 'error',
        title: 'Error',
        text: 'Fout bij bijwerken van gebruikersgegevens',
      });
    }
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    // Check if passwords match
    if (!passwordData.newPassword || !passwordData.confirmNewPassword) {
      Swal2.fire({
        icon: 'error',
        title: 'Error',
        text: 'Vul beide wachtwoordvelden in',
      });
      return;
    }

    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      Swal2.fire({
        icon: 'error',
        title: 'Error',
        text: 'Wachtwoorden komen niet overeen',
      });
      return; // Prevent further execution
    }

    try {
      await UserAPIs.changeUserPassword(passwordData);
      Swal2.fire({
        icon: 'success',
        title: 'Gelukt',
        text: 'Wachtwoord bijgewerkt',
      }).then(() => {
        window.location.reload(false);
      });
    } catch (error) {
      Swal2.fire({
        icon: 'error',
        title: 'Error',
        text: 'Fout bij updaten van wachtwoord',
      });
    }
  };

  const checkEnterSubmitDetails = (e) => {
    if (e.key === 'Enter') {
      handleSubmitPersonalDetails(e);
    }
  };

  const checkEnterSubmitPassword = (e) => {
    if (e.key === 'Enter') {
      handleSubmitPassword(e);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ pt: 6, pb: 9 }} className="profile-settings">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="user-heading">
          <div className="profile-img">
            <FaUserCircle />
          </div>
          <h1>
            Hallo
            {' '}
            <b>{user.first_name}</b>
          </h1>
        </div>
        <Button
          onClick={handleDeleteAccount}
          className="btn btn-red"
          startIcon={<TbTrash />}
          sx={{ mb: 2 }}
        >
          Verwijder mijn account
        </Button>
      </div>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <div className="panel">
            <div className="heading small">
              <TbUserSquareRounded />
              <h2>Persoonlijke gegevens</h2>
            </div>
            <label>
              Voornaam
              <span className="required">*</span>
            </label>
            <input
              type="text"
              name="firstName"
              value={userData.firstName}
              onChange={handleUserInputChange}
              placeholder="Bijv. Jeroen"
              onKeyDown={checkEnterSubmitDetails}
            />
            <label>Tussenvoegsel</label>
            <input
              type="text"
              name="infix"
              value={userData.infix}
              onChange={handleUserInputChange}
              placeholder="Bijv. van der"
              onKeyDown={checkEnterSubmitDetails}
            />
            <label>
              Achternaam
              <span className="required">*</span>
            </label>
            <input
              type="text"
              name="surname"
              value={userData.surname}
              onChange={handleUserInputChange}
              placeholder="Bijv. Berg"
              onKeyDown={checkEnterSubmitDetails}
            />
            <label>
              Email
            </label>
            <input
              type="email"
              value={userData.email}
              disabled
            />
            <label>
              Beroep
              <span className="required">*</span>
            </label>
            <select
              onChange={(e) => setUserData({ ...userData, positionRole: e.target.value })}
            >
              <option value="1" disabled>Beroep</option>
              {jobRoles.map((role) => (
                <option
                  value={role.label}
                  key={role.label}
                  selected={role.label === userData.positionRole}
                >
                  {role.label}
                </option>
              ))}
            </select>
            <label>Kantoornaam</label>
            <input
              type="text"
              name="officeName"
              value={userData.officeName}
              onChange={handleUserInputChange}
              placeholder="Credit Life"
              onKeyDown={checkEnterSubmitDetails}
            />
            <label>
              Telefoonnummer
            </label>
            <input
              type="tel"
              name="phoneNumber"
              value={userData.phoneNumber}
              onChange={handleUserInputChange}
              placeholder="Bijv. +31612345678"
              onKeyDown={checkEnterSubmitDetails}
            />
            <Button
              onClick={handleSubmitPersonalDetails}
              className="btn btn-green"
              startIcon={<TbDeviceFloppy />}
              sx={{ mt: 3 }}
            >
              Wijzigingen Opslaan
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className="panel">
            <div className="heading small">
              <TbLockSquareRounded />
              <h2>Wachtwoord</h2>
            </div>
            <label>Nieuw wachtwoord</label>
            <input
              type="password"
              name="newPassword"
              value={passwordData.newPassword}
              onChange={handlePasswordInputChange}
              placeholder="Typ nieuw wachtwoord"
              onKeyDown={checkEnterSubmitPassword}
            />
            <label>Bevestig nieuw wachtwoord</label>
            <input
              type="password"
              name="confirmNewPassword"
              value={passwordData.confirmNewPassword}
              onChange={handlePasswordInputChange}
              placeholder="Typ nieuw wachtwoord bevestiging"
              onKeyDown={checkEnterSubmitPassword}
            />
            <Button
              className="btn btn-green"
              startIcon={<TbLockCheck />}
              sx={{ mt: 3 }}
              onClick={handleSubmitPassword}
            >
              Verander wachtwoord
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ProfileSettingsView;
