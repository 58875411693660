import React from 'react';
import PropTypes from 'prop-types';
import { TbMoodSad, TbMoodNeutral, TbMoodHappy } from 'react-icons/tb';
import { IconButton, Button } from '@mui/material';

function FeedbackScoreFilter({ selectedScore, onSelectScore }) {
  const handleSelect = (score) => () => {
    onSelectScore(score);
  };

  return (
    <div style={{
      display: 'flex', gap: '8px', marginLeft: 20,
    }}
    >
      <IconButton
        color={selectedScore === 1 ? 'primary' : 'default'}
        onClick={handleSelect(1)}
      >
        <TbMoodSad size={24} color="red" />
      </IconButton>
      <IconButton
        color={selectedScore === 2 ? 'primary' : 'default'}
        onClick={handleSelect(2)}
      >
        <TbMoodNeutral size={24} color="orange" />
      </IconButton>
      <IconButton
        color={selectedScore === 3 ? 'primary' : 'default'}
        onClick={handleSelect(3)}
      >
        <TbMoodHappy size={24} color="green" />
      </IconButton>
      <Button
        className="btn btn-green"
        sx={{ mt: 0 }}
        onClick={handleSelect(null)}
      >
        Alle
      </Button>
    </div>
  );
}

FeedbackScoreFilter.propTypes = {
  selectedScore: PropTypes.number,
  onSelectScore: PropTypes.func.isRequired,
};

FeedbackScoreFilter.defaultProps = {
  selectedScore: null, // Default value for selectedScore
};

export default FeedbackScoreFilter;
