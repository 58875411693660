import React, {
  useState, useContext, useEffect, useRef,
} from 'react';
import './ChatView.scss';
import {
  Button, CircularProgress, Container, Grid,
} from '@mui/material';
import { IconMessages, IconSend } from '@tabler/icons-react';
import { CONVERSATIONS_URL } from '../../Constants/URLS';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import FaqSidebar from '../../Components/FaqSidebar';
import ChatMessage from '../../Components/Chat/ChatMessage';

function ChatView() {
  const userContext = useContext(UserContext);
  const utils = apiUtilsHook(userContext);
  const [messages, setMessages] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const [message, setMessage] = useState('');
  const [expandedMessageId, setExpandedMessageId] = useState(null);
  const messagesContainerRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const sendMessage = (msg) => {
    if (!msg) return;
    const data = {
      message: msg,
    };
    setMessages((prev) => [...prev, { message: msg, sender: 'user' }]);
    setMessage('');
    utils.post(`${CONVERSATIONS_URL}${conversationId}/add_message/`, data).then((response) => {
      // remove last message from state and replace with the response
      setMessages((prev) => [...prev.slice(0, -1), response.data.message, response.data.response]);
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    });
  };

  useEffect(() => {
    if (messages.length === 0) return;
    if (messages.length % 2 === 1) {
      setExpandedMessageId(messages[messages.length - 1].id);
    } else {
      setExpandedMessageId(messages[messages.length - 2].id);
    }
  }, [messages]);

  const toggleExpandMessage = (messageId) => {
    setExpandedMessageId((prev) => (prev === messageId ? null : messageId));
  };

  const renderMessages = () => (
    <>
      {/* only map over even indexes */}
      {messages.filter((_, index) => index % 2 === 0).map((msg, index) => (
        <ChatMessage
          toggleExpandMessage={() => toggleExpandMessage(msg.id)}
          expanded={expandedMessageId === msg.id}
          expandable={messages.length > 2}
          key={index}
          question={msg}
          response={messages[index * 2 + 1] || null}
        />
      ))}
    </>
  );

  useEffect(() => {
    utils.get(`${CONVERSATIONS_URL}get_conversation/`).then((response) => {
      setConversationId(response.data.id);
      utils.get(`${CONVERSATIONS_URL}${response.data.id}/`).then((r) => {
        setMessages(r.data.messages);
        setLoading(false);
      });
    });
  }, []);

  return (
    <Container maxWidth="lg" className="chat-view">
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4}>
          {/* <Button onClick={newConvo}>New</Button> */}
          <FaqSidebar onFaqClick={sendMessage} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <div className="chat-container">
            <div className="chat-area">
              <div className="messages-container" ref={messagesContainerRef}>
                {(!loading && messages.length === 0) ? (
                  <div className="welcome-container">
                    <h1>
                      <IconMessages />
                      Welkom bij de chatfunctie van het AO platform.
                    </h1>
                    <p>Stel mij je vraag!</p>
                  </div>
                ) : (
                  renderMessages()
                )}
                {(loading || messages.length % 2 === 1) && (
                  <CircularProgress />
                )}
              </div>
              <div className="message-input-container">
                <textarea
                  onChange={(event) => setMessage(event.target.value.replace('\n', ''))}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && event.target.value.length > 0) {
                      sendMessage(message);
                    }
                  }}
                  value={message}
                  placeholder="Stel je vraag hier..."
                />
                <Button
                  onClick={() => {
                    if (message.trim() !== '') {
                      sendMessage(message);
                    }
                  }}
                >
                  <IconSend />
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ChatView;
