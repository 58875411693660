import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { TbDeviceDesktopAnalytics } from 'react-icons/tb';
import { Button, Container } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import { IconDeviceFloppy } from '@tabler/icons-react';
import Swal from 'sweetalert2';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '../../Constants/URLS';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import getEditorApiKey from '../../Utils/TinymceUtils';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import LoadingScreen from '../LoadingScreen';
import termsAndConditions from '../TermsAndConditions';

const editorApiKey = getEditorApiKey();

export default function LegalTexts() {
  const [termsOfUse, setTermsOfUse] = useState(null);
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [termsNotFound, setTermsNotFound] = useState(false);
  const [privacyNotFound, setPrivacyNotFound] = useState(false);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  // eslint-disable-next-line
  const [newPrivacyPolicy, setNewPrivacyPolicy] = useState('');
  // eslint-disable-next-line
  const [newTermsOfUse, setNewTermsOfUse] = useState('');

  const fetchPolicies = () => {
    api.getNoAuth(`${TERMS_OF_USE_URL}latest/`).then((response) => {
      setTermsOfUse(response.data);
      setNewTermsOfUse(response.data.text);
    }).catch((error) => {
      if (error.response.status === 404) {
        setTermsNotFound(true);
      }
    });
    api.getNoAuth(`${PRIVACY_POLICY_URL}latest/`).then((response) => {
      setPrivacyPolicy(response.data);
      setNewPrivacyPolicy(response.data.text);
    }).catch((error) => {
      if (error.response.status === 404) {
        setPrivacyNotFound(true);
      }
    });
  };

  useEffect(() => {
    fetchPolicies();
  }, []);

  const loading = (!termsOfUse && !termsNotFound) || (!privacyPolicy && !privacyNotFound);

  if (loading) {
    return <LoadingScreen />;
  }

  const saveChanges = () => {
    if ((termsNotFound && newTermsOfUse !== '') || (!termsNotFound && newTermsOfUse !== termsOfUse.text)) {
      api.post(`${TERMS_OF_USE_URL}`, { text: newTermsOfUse }).then((r) => {
        setTermsOfUse(r.data);
      });
    }
    if ((privacyNotFound && newPrivacyPolicy !== '') || (!privacyNotFound && newPrivacyPolicy !== privacyPolicy.text)) {
      api.post(`${PRIVACY_POLICY_URL}`, { text: newPrivacyPolicy }).then((r) => {
        setPrivacyPolicy(r.data);
      });
    }
    Swal.fire({
      title: 'Succesvol opgeslagen',
      icon: 'success',
      showConfirmButton: false,
      timer: 1500,
    }).then(() => fetchPolicies());
  };

  const disableButton = ((!termsNotFound && newTermsOfUse === termsOfUse.text)
      || (termsNotFound && newTermsOfUse === ''))
    && ((!privacyNotFound && newPrivacyPolicy === privacyPolicy.text)
      || (privacyNotFound && newPrivacyPolicy === ''));

  return (
    <Container maxWidth="lg" sx={{ pt: 6, pb: 10 }}>
      <div className="heading heading-left">
        <TbDeviceDesktopAnalytics />
        <h1>Data dashboard</h1>
      </div>

      <h3 style={{ marginTop: 40 }}>Gebruikersvoorwaarden</h3>
      <Editor
        id="terms-editor"
        apiKey={editorApiKey}
        init={{
          plugins: 'lists',
          toolbar: 'undo redo | bold italic underline | numlist bullist | indent outdent | removeformat',
          menubar: false,
        }}
        initialValue={termsNotFound ? '' : termsOfUse.text}
        onEditorChange={(content) => setNewTermsOfUse(content)}
      />
      <p style={{ marginTop: 10 }}>
        Laatst gewijzigd op:
        {' '}
        <b>
          {termsOfUse ? moment(termsAndConditions.created_at).format('DD-MM-YYYY') : 'Nooit'}
        </b>
      </p>

      <h3 style={{ marginTop: 40 }}>Privacybeleid</h3>
      <Editor
        id="privacy-editor"
        apiKey={editorApiKey}
        init={{
          plugins: 'lists',
          toolbar: 'undo redo | bold italic underline | numlist bullist | indent outdent | removeformat',
          menubar: false,
        }}
        initialValue={privacyNotFound ? '' : privacyPolicy.text}
        onEditorChange={(content) => setNewPrivacyPolicy(content)}
      />
      <p style={{ marginTop: 10 }}>
        Laatst gewijzigd op:
        {' '}
        <b>
          {privacyPolicy ? moment(privacyPolicy.created_at).format('DD-MM-YYYY') : 'Nooit'}
        </b>
      </p>
      <Button
        className="btn btn-green"
        startIcon={<IconDeviceFloppy />}
        sx={{ mt: 3 }}
        onClick={saveChanges}
        disabled={disableButton}
      >
        Opslaan
      </Button>
    </Container>
  );
}
