import React, { useState, useEffect, useContext } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
  TableSortLabel, Container, Tooltip,
} from '@mui/material';
import {
  TbMoodSad, TbMoodNeutral, TbMoodHappy, TbMessage2Exclamation,
} from 'react-icons/tb';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { FEEDBACK_TABLE_URL } from '../../Constants/URLS';
import FeedbackScoreFilter from './FeedbackScoreFilter';
import {
  containerStyles, tablePaginationStyles,
} from '../../Components/TableStyles/TableStyles';
import './FeedbackView.scss';

function FeedbackTable() {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [feedbacks, setFeedbacks] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [search, setSearch] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [selectedScore, setSelectedScore] = useState(null);

  const fetchFeedbacks = async () => {
    const response = await api.get(FEEDBACK_TABLE_URL, {
      params: {
        page,
        page_size: rowsPerPage,
        ordering: `${order === 'desc' ? '-' : ''}${orderBy}`,
        search,
        feedback_score: selectedScore,
      },
    });
    setFeedbacks(response.data.results);
    setTotalCount(response.data.count);
  };

  useEffect(() => {
    fetchFeedbacks();
  }, [page, rowsPerPage, order, orderBy, search, selectedScore]);

  return (
    <Container maxWidth="lg" sx={containerStyles}>
      <div className="heading">
        <TbMessage2Exclamation />
        <h1>Feedback</h1>
      </div>
      <div className="feedback-view">
        <div
          style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between',
          }}
          className="backoffice-filters-search-bar"
        >
          <input
            placeholder="Zoek…"
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
              setPage(1);
            }}
          />
          <FeedbackScoreFilter
            selectedScore={selectedScore}
            onSelectScore={(score) => {
              setSelectedScore(score);
              setPage(1);
            }}
          />
        </div>
        <TableContainer>
          <Table>
            <TableHead className="table-header">
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'created_at'}
                    direction={orderBy === 'created_at' ? order : 'asc'}
                    onClick={() => {
                      const isAsc = orderBy === 'created_at' && order === 'asc';
                      setOrder(isAsc ? 'desc' : 'asc');
                      setOrderBy('created_at');
                    }}
                  >
                    Datum
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'feedback_page'}
                    direction={orderBy === 'feedback_page' ? order : 'asc'}
                    onClick={() => {
                      const isAsc = orderBy === 'feedback_page' && order === 'asc';
                      setOrder(isAsc ? 'desc' : 'asc');
                      setOrderBy('feedback_page');
                    }}
                  >
                    Pagina
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'feedback_score'}
                    direction={orderBy === 'feedback_score' ? order : 'asc'}
                    onClick={() => {
                      const isAsc = orderBy === 'feedback_score' && order === 'asc';
                      setOrder(isAsc ? 'desc' : 'asc');
                      setOrderBy('feedback_score');
                    }}
                  >
                    Feedbackscore
                  </TableSortLabel>
                </TableCell>
                <TableCell>Bericht</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'created_by_email'}
                    direction={orderBy === 'created_by_email' ? order : 'asc'}
                    onClick={() => {
                      const isAsc = orderBy === 'created_by_email' && order === 'asc';
                      setOrder(isAsc ? 'desc' : 'asc');
                      setOrderBy('created_by_email');
                    }}
                  >
                    Gebruiker
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feedbacks.map((feedback) => (
                <Tooltip title={feedback.feedback_text} placement="top" arrow>
                  <TableRow key={feedback.id} className="table-row">
                    <TableCell className="table-cell">
                      <div>
                        {new Date(feedback.created_at).toLocaleString()}
                      </div>
                    </TableCell>
                    <TableCell className="table-cell">{feedback.feedback_page}</TableCell>
                    <TableCell className="table-cell">
                      {feedback.feedback_score === 1 && <TbMoodSad size={24} color="red" />}
                      {feedback.feedback_score === 2 && <TbMoodNeutral size={24} color="orange" />}
                      {feedback.feedback_score === 3 && <TbMoodHappy size={24} color="green" />}
                    </TableCell>
                    <TableCell className="table-cell">
                      {feedback.feedback_text.slice(0, 100)}
                      ...
                    </TableCell>
                    <TableCell className="table-cell">{feedback.created_by_email}</TableCell>
                  </TableRow>
                </Tooltip>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          page={page - 1}
          onPageChange={(event, newPage) => setPage(newPage + 1)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(1);
          }}
          labelRowsPerPage="Rijen per pagina:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count !== -1 ? count : `meer dan ${to}`}`}
          style={tablePaginationStyles}
        />
      </div>
    </Container>
  );
}

export default FeedbackTable;
