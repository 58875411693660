import React, {
  useState, useContext, useEffect,
} from 'react';
import {
  Container, Button, Switch, FormControlLabel, Tooltip,
} from '@mui/material';
import { AsyncPaginate } from 'react-select-async-paginate';
import Swal2 from 'sweetalert2';
import {
  TbPlus, TbLoader3,
} from 'react-icons/tb';
import { useDropzone } from 'react-dropzone';
import { IconHelpOctagon, IconTrash } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { FREQUENTLY_ASKED_QUESTIONS_URL, TOPICS_LIGHT_URL, SOURCES_URL } from '../../../../Constants/URLS';
import { containerStyles } from '../../../../Components/TableStyles/TableStyles';
import './FAQAddView.scss';
import topicStyles from '../../topicStyles';
import sourceIcon from '../../../../Utils/SourceUtils';

const switchStyles = {
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#BAD60A',
    '&:hover': {
      backgroundColor: 'rgba(186, 214, 10, 0.08)',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#A3C009', // 4% darker than #BAD60A
  },
};

function FAQAddView() {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [featured, setFeatured] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    acceptedFiles, getRootProps, getInputProps,
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'text/plain': ['.txt'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    maxFiles: 1,
  });

  const handleSelectTopics = (selectedOptions) => {
    setSelectedTopics(selectedOptions || []);
  };

  const loadTopics = async (search) => {
    const response = await api.get(`${TOPICS_LIGHT_URL}?search=${search}`);

    const loadedTopics = {
      options: response.data.map((topic) => ({
        value: topic.id,
        label: topic.name,
      })),
      hasMore: false,
    };

    return loadedTopics;
  };

  const uploadSources = async () => {
    if (acceptedFiles.length === 0) {
      return [];
    }

    const uploadPromises = acceptedFiles.map((file) => {
      const formData = new FormData();
      formData.append('source_file', file);

      return api.post(SOURCES_URL, formData);
    });

    try {
      const responses = await Promise.all(uploadPromises);
      return responses
        .filter((response) => response.status === 201)
        .map((response) => response.data.id);
    } catch (error) {
      Swal2.fire({
        title: 'Fout',
        text: `Er is een fout opgetreden bij het uploaden van de bron: ${error.response ? error.response.data.message : error.message}`,
        icon: 'error',
      });
      return [];
    }
  };

  const submitFAQ = async () => {
    setIsLoading(true);
    try {
      const uploadedSourceIds = await uploadSources();
      const response = await api.post(FREQUENTLY_ASKED_QUESTIONS_URL, {
        question,
        answer,
        topics: selectedTopics.map((topic) => topic.value),
        sources: uploadedSourceIds,
        featured,
      });

      if (response.status === 201) {
        // Show success alert
        Swal2.fire({
          title: 'Succesvol',
          text: 'Het veelgestelde vraag is toegevoegd.',
          icon: 'success',
        });
        navigate(`/backoffice/faqs/edit/${response.data.id}`);
      }
    } catch (error) {
      // Show error alert
      Swal2.fire({
        title: 'Fout',
        text: `Er is een fout opgetreden bij het toevoegen van het veelgestelde vraag: ${error.response ? error.response.data.message : error.message}`,
        icon: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Update form validity
  useEffect(() => {
    setIsFormValid(question.trim().length > 0 && answer.trim().length > 0);
  }, [question, answer]);

  return (
    <Container maxWidth="lg" sx={containerStyles}>
      <div className="heading">
        <IconHelpOctagon />
        <h1>Voeg een veelgestelde vraag toe</h1>
      </div>
      <div className="chat-backoffice-view">
        <h4>Vraag</h4>
        <input
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Typ hier je vraag..."
        />
        <h4>Antwoord</h4>
        <textarea
          rows={4}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          placeholder="Typ hier je antwoord..."
        />
        <h4>Onderwerpen</h4>
        <AsyncPaginate
          loadOptions={loadTopics}
          onChange={handleSelectTopics}
          isSearchable
          isClearable
          additional={{}}
          isMulti
          debounceTimeout={300}
          placeholder="Selecteer onderwerpen..."
          styles={topicStyles}
        />
        <h4>Bronnen</h4>
        {/* eslint-disable-next-line */}
        <div {...getRootProps({className: 'dropzone'})}>
          {/* eslint-disable-next-line */}
          <input {...getInputProps()} />
          {acceptedFiles.length > 0 ? (
            <Tooltip title="Verwijder bestand" arrow placement="top">
              <div className="dropzone-file">
                <img src={sourceIcon({ source_file: acceptedFiles[0].path })} alt="source-icon" />
                {acceptedFiles[0].path}
                <div className="overlay">
                  <IconTrash />
                </div>
              </div>
            </Tooltip>
          ) : (
            <p>Drag & drop het bestand hierheen of klik om een bestand te selecteren.</p>
          )}
        </div>
        <div className="moderation-container">
          <FormControlLabel
            control={
            (
              <Switch
                checked={featured}
                onChange={(e) => setFeatured(e.target.checked)}
                name="featured"
                sx={switchStyles}
              />
            )
          }
            label="Aanbevolen"
          />
        </div>
        <Button
          startIcon={isLoading ? <TbLoader3 size={20} className="loader" /> : <TbPlus />}
          className="btn btn-green"
          onClick={submitFAQ}
          disabled={!isFormValid || isLoading}
          sx={{ mt: 3 }}
        >
          Versturen
        </Button>
      </div>
    </Container>
  );
}

export default FAQAddView;
