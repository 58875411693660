import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, Dialog, DialogContent, DialogTitle,
} from '@mui/material';
import { IconSend } from '@tabler/icons-react';
import { UserContext } from '../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../Utils/ApiUtilsHook';
import { CONVERSATIONS_URL } from '../../../Constants/URLS';

function TestChat({
  open, close, title, id,
}) {
  const userContext = useContext(UserContext);
  const utils = apiUtilsHook(userContext);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const sendMessage = () => {
    if (!message) return;
    const data = {
      message,
    };
    setMessages((prev) => [...prev, { message, sender: 'user' }]);
    setMessage('');
    setTimeout(scrollToBottom, 10);
    setLoading(true);
    utils.post(`${CONVERSATIONS_URL}${conversationId}/add_message/`, data).then((response) => {
      setLoading(false);
      setMessages((prev) => [
        ...prev.slice(0, -1),
        { ...response.data.message, sender: 'user' },
        { ...response.data.response, sender: 'chatbot' },
      ]);
      setTimeout(scrollToBottom, 10);
    });
  };

  useEffect(() => {
    let url;
    if (!id) {
      url = `${CONVERSATIONS_URL}get_conversation/?test=true`;
    } else {
      url = `${CONVERSATIONS_URL}get_conversation/?test=true&source=${id}`;
    }
    utils.get(url).then((response) => {
      setConversationId(response.data.id);
    });
  }, []);

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
      <DialogTitle className="test-source-dialog-title">
        Test Bron
        {' '}
        {`"${title}"`}
      </DialogTitle>
      <DialogContent className="test-source-dialog-content">
        <div className="messages-container" ref={containerRef}>
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.sender}`}>
              {msg.message}
            </div>
          ))}
          {loading && (
            <div className="message chatbot">
              <CircularProgress />
            </div>
          )}
        </div>
        <div className="message-box">
          <textarea
            placeholder="Jouw bericht..."
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
              }
            }}
          />
          <button aria-label="send" type="button" onClick={sendMessage}><IconSend /></button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

TestChat.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.number,
};

TestChat.defaultProps = {
  id: null,
};

export default TestChat;
