import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import './FaqSidebar.scss';
import { IconZoomQuestion } from '@tabler/icons-react';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import { FREQUENTLY_ASKED_QUESTIONS_URL } from '../../Constants/URLS';

function FaqSidebar({ onFaqClick }) {
  const [faqs, setFaqs] = useState([]);
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);

  const fetchFaqs = async (featured = true) => {
    try {
      const response = await api.get(`${FREQUENTLY_ASKED_QUESTIONS_URL}?featured=${featured}`);
      setFaqs(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching FAQs:', error);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  return (
    <div className="faq">
      <h1>
        <IconZoomQuestion />
        Veelgestelde vragen
      </h1>
      {faqs.map((faq) => (
        <button className="faq-question" key={faq.id} type="button" onClick={() => onFaqClick(faq.question)}>
          {faq.question}
        </button>
      ))}
    </div>
  );
}

FaqSidebar.propTypes = {
  onFaqClick: PropTypes.func.isRequired,
};

export default FaqSidebar;
