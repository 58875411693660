import React, {
  createContext, useState, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../UserProvider/UserProvider';
import { MATCHES_URL } from '../../Constants/URLS';

const MatchesContext = createContext();

export const useMatches = () => useContext(MatchesContext);

export default function MatchesProvider({ children }) {
  const [matches, setMatches] = useState([]);
  const [paginatedMatches, setPaginatedMatches] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const matchesPerPage = 10;

  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);

  // Fetch all matches
  const fetchMatches = async () => {
    try {
      const response = await api.get(`${MATCHES_URL}?ranking_similarity=1&pdf_downloaded=true`);
      setMatches(response.data.results);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching matches:', error);
    }
  };

  // Fetch paginated matches
  const fetchPaginatedMatches = async (page = 1) => {
    try {
      const response = await api.get(`${MATCHES_URL}?ranking_similarity=1&pdf_downloaded=true&page=${page}`);
      setCurrentPage(page);
      setPaginatedMatches(response.data.results);
      setTotalPages(Math.ceil(response.data.count / matchesPerPage));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching matches:', error);
    }
  };

  // Add functions to handle pagination
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      fetchPaginatedMatches(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      fetchPaginatedMatches(currentPage - 1);
    }
  };

  // Fetch matches on mount
  useEffect(() => {
    if (Object.keys(userContext.user).length > 1) {
      fetchMatches();
      fetchPaginatedMatches();
    }
  }, [userContext.user]);

  return (
    <MatchesContext.Provider
      value={{
        matches,
        fetchMatches,
        paginatedMatches,
        fetchPaginatedMatches,
        goToNextPage,
        goToPreviousPage,
        currentPage,
        totalPages,
      }}
    >
      {children}
    </MatchesContext.Provider>
  );
}

MatchesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
