import React, {
  useState, useEffect, useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Masonry } from '@mui/lab';
import {
  Container, Button, Grid, TextField,
} from '@mui/material';
import {
  TbBriefcase, TbClipboardData, TbDeviceDesktopAnalytics, TbFolders,
  TbUsersGroup, TbArrowLeft, TbArrowRight, TbDownload,
  TbSearch, TbUserCircle,
} from 'react-icons/tb';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { IconEyeOff } from '@tabler/icons-react';
import Panel from '../../Components/Panel/Panel';
import useDataDashboardApi from '../../APIs/DataDashboardApiWrapper';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import { PROFESSIONS_URL } from '../../Constants/URLS';
import './DataDashboardView.scss';
import AccessDeniedMessage from '../../Components/AccessDeniedMessage';
import LoadingScreen from '../LoadingScreen';
import jsonToCSV from '../../Components/JsonToCSV/JsonToCSV';

function DataDashboardView() {
  const userContext = useContext(UserContext);
  const api = useDataDashboardApi(userContext);
  const genericApi = apiUtilsHook(userContext);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  // Define the roles that have access to this view
  const allowedRoles = ['Staff', 'Admin'];
  const hasAccess = allowedRoles.includes(userContext.user.role);

  // Get the current date
  const currentDate = new Date();
  const latestYear = currentDate.getFullYear();
  const latestMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
  const [period, setPeriod] = useState('monthly');
  const [date, setDate] = useState(`${latestYear}-${latestMonth}`);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  // States for User Panel
  const [activeUsersData, setActiveUsersData] = useState(null);
  const [userMatchesData, setUserMatchesData] = useState(null);

  // States for Client Panel
  const [averageAgeData, setAverageAgeData] = useState(null);
  const [professionPopularityData, setProfessionPopularityData] = useState(null);

  // States for Matches Panel
  const [caseStudyPopularityData, setCaseStudyPopularityData] = useState(null);
  const [totalMatchesData, setTotalMatchesData] = useState(null);

  // States for Misc Panel
  const [pdfDownloadCountData, setPdfDownloadCountData] = useState(null);

  // States for Professions panel
  const [badlyMatchedJobRolesData, setBadlyMatchedJobRolesData] = useState([]);
  const [badlyMatchedColumns] = useState([
    { key: 'client_job_role', header: 'Beroep' },
    { key: 'case_study_job_role', header: 'Huidige match' },
    { key: 'count', header: 'Aantal' },
  ]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize] = useState(5);

  // Handle click in Professions table
  const handleDashboardIconClick = async (row) => {
    const setPrepublishStatus = async (professionId, status = true) => {
      try {
        await genericApi.patch(`${PROFESSIONS_URL}${professionId}/`, { prepublish_list: status });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error setting prepublish status for profession id', professionId, ' with error: ', error);
      }
    };

    // Add profession of client to doorgestuurde beroepenlijst
    await setPrepublishStatus(row.client_profession_id);

    // Navigate to case generator
    window.scrollTo(0, 0);
    navigate('/backoffice/case-content-generator');
  };

  // Data download function
  const downloadDataAsZip = () => {
    const zip = new JSZip();
    let periodString = period === 'custom' ? `${dateFrom}_${dateTo}` : date;
    if (period === 'all') {
      periodString = 'Altijd';
    }
    // Convert JSON data to CSV and add to the zip file
    zip.file(`AOP_ActieveGebruikers_${periodString}.csv`, jsonToCSV(activeUsersData));
    zip.file(`AOP_GebruikersMatches_${periodString}.csv`, jsonToCSV(userMatchesData.data));
    zip.file(`AOP_GemiddeldeLeeftijd_${periodString}.csv`, jsonToCSV(averageAgeData));
    zip.file(`AOP_BeroepPopulariteit_${periodString}.csv`, jsonToCSV(professionPopularityData));
    zip.file(`AOP_CaseStudyPopulariteit_${periodString}.csv`, jsonToCSV(caseStudyPopularityData));
    zip.file(`AOP_TotaalAantalMatches_${periodString}.csv`, jsonToCSV(totalMatchesData));
    zip.file(`AOP_AantalPdfDownloaden_${periodString}.csv`, jsonToCSV(pdfDownloadCountData));
    zip.file(`AOP_SlechtPassendeBeroepen_${periodString}.csv`, jsonToCSV(badlyMatchedJobRolesData));

    // Generate the zip file and trigger the download
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `AOP_Data_${periodString}.zip`);
    });
  };

  const handlePeriodChange = (event) => {
    const newPeriod = event.target.value;
    setPeriod(newPeriod);

    if (newPeriod === 'custom') {
      // Set default custom range to the current month
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
      setDateFrom(startOfMonth);
      setDateTo(endOfMonth);
    } else if (newPeriod !== 'all') {
      // Adjust 'date' for non-custom, non-all periods
      const newDate = newPeriod === 'monthly' ? moment().format('YYYY-MM') : moment().format('YYYY');
      setDate(newDate);
    }
  };

  const navigateDate = (direction) => {
    const format = period === 'monthly' ? 'YYYY-MM' : 'YYYY';
    const unit = period === 'monthly' ? 'months' : 'years';
    setDate(moment(date, format).add(direction, unit).format(format));
  };

  // Transformation function for BarGraph data
  const transformBarGraphData = (data, categoryKey, valueKey) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    data.map((item) => ({
      category: item[categoryKey],
      value: item[valueKey],
    }));

  const transformTabularData = (data) => {
    if (!data) {
      // eslint-disable-next-line no-console
      console.error('Data is undefined or null');
      return { columns: [], data: [] };
    }

    let dataArray = [];

    if (Array.isArray(data)) {
      // Data is already an array, no need to modify it
      dataArray = data;
    } else if (typeof data === 'object') {
      // Data is an object, convert it to an array of objects
      dataArray = Object.entries(data).map(([email, count]) => ({
        email,
        count,
      }));
    }

    // Define columns
    const columns = [
      { key: 'email', header: 'User Email' },
      { key: 'count', header: 'Number of Matches' },
    ];
    return { columns, data: dataArray };
  };

  // Fetch data for "Slecht Gematchte Beroepen"
  const fetchBadlyMatchedJobRoles = async (page = 1) => {
    const params = { period, page, page_size: pageSize };
    if (period === 'custom') {
      params.date_from = dateFrom;
      params.date_to = dateTo;
    } else if (period !== 'all') {
      params.date = date;
    }
    const response = await api.getBadlyMatchedJobRoles(params);
    if (response && response.data) {
      setBadlyMatchedJobRolesData(response.data.results);
      setTotalCount(response.data.count);
    }
  };

  useEffect(() => {
    fetchBadlyMatchedJobRoles();
  }, [period, date, dateFrom, dateTo]);

  const hideProfessionFromBadlyMatched = (row) => {
    api.hideProfessionFromBadlyMatched(row.client_profession_id).then(() => {
      fetchBadlyMatchedJobRoles();
    });
  };

  // Fetch data for Active Users
  useEffect(() => {
    async function fetchDataForActiveUsers() {
      const params = { period };
      if (period === 'custom') {
        params.date_from = dateFrom;
        params.date_to = dateTo;
      } else if (period !== 'all') {
        params.date = date;
      }
      const response = await api.getActiveUsersMatches(params);
      setActiveUsersData(response.data);
    }

    fetchDataForActiveUsers();
  }, [period, date, dateFrom, dateTo]);

  // Fetch data for Average Age
  useEffect(() => {
    async function fetchDataForAverageAge() {
      const params = { period };
      if (period === 'custom') {
        params.date_from = dateFrom;
        params.date_to = dateTo;
      } else if (period !== 'all') {
        params.date = date;
      }
      const response = await api.getAverageClientAge(params);
      setAverageAgeData(response.data);
    }
    fetchDataForAverageAge();
  }, [period, date, dateFrom, dateTo]);

  // Fetch data for Profession Popularity
  useEffect(() => {
    async function fetchDataForProfessionPopularity() {
      const params = { period };
      if (period === 'custom') {
        params.date_from = dateFrom;
        params.date_to = dateTo;
      } else if (period !== 'all') {
        params.date = date;
      }
      const response = await api.getProfessionPopularity(params);
      const transformedData = transformBarGraphData(response.data, 'job_role', 'count');
      setProfessionPopularityData(transformedData);
    }
    fetchDataForProfessionPopularity();
  }, [period, date, dateFrom, dateTo]);

  // Fetch data for User Matches
  useEffect(() => {
    async function fetchDataForUserMatches() {
      const params = { period, search: searchQuery };
      if (period === 'custom') {
        params.date_from = dateFrom;
        params.date_to = dateTo;
      } else if (period !== 'all') {
        params.date = date;
      }
      const response = await api.getUserMatches(params);

      if (response && response.data) {
        const transformedData = transformTabularData(response.data);
        setUserMatchesData(transformedData);
      } else {
        // eslint-disable-next-line no-console
        console.error('getUserMatches response is undefined or null');
      }
    }
    fetchDataForUserMatches();
  }, [period, date, dateFrom, dateTo, searchQuery]);

  // Fetch data for Case Study Popularity
  useEffect(() => {
    async function fetchDataForCaseStudyPopularity() {
      const params = { period };
      if (period === 'custom') {
        params.date_from = dateFrom;
        params.date_to = dateTo;
      } else if (period !== 'all') {
        params.date = date;
      }
      const response = await api.getCaseStudyPopularity(params);
      const transformedData = transformBarGraphData(response.data, 'case_study', 'count');
      setCaseStudyPopularityData(transformedData);
    }
    fetchDataForCaseStudyPopularity();
  }, [period, date, dateFrom, dateTo]);

  // Fetch data for Total Matches
  useEffect(() => {
    async function fetchDataForTotalMatches() {
      const params = { period };
      if (period === 'custom') {
        params.date_from = dateFrom;
        params.date_to = dateTo;
      } else if (period !== 'all') {
        params.date = date;
      }
      const response = await api.getTotalMatches(params);
      setTotalMatchesData(response.data);
    }
    fetchDataForTotalMatches();
  }, [period, date, dateFrom, dateTo]);

  // Fetch data for Total Matches
  useEffect(() => {
    async function fetchDataForPDFDownloads() {
      const params = { period };
      if (period === 'custom') {
        params.date_from = dateFrom;
        params.date_to = dateTo;
      } else if (period !== 'all') {
        params.date = date;
      }
      const response = await api.getPDFDownloads(params);
      setPdfDownloadCountData(response.data);
    }
    fetchDataForPDFDownloads();
  }, [period, date, dateFrom, dateTo]);

  // Loading condition
  if (!activeUsersData
    || !averageAgeData
    || !userMatchesData
    || !pdfDownloadCountData
    || !badlyMatchedJobRolesData
    || !professionPopularityData
    || !caseStudyPopularityData
    || !totalMatchesData) {
    return <LoadingScreen />;
  }

  if (userContext.user && userContext.user.first_name && !hasAccess) {
    return <AccessDeniedMessage />;
  }

  return (
    <Container maxWidth="lg" sx={{ pt: 6, pb: 9 }}>
      <div className="data-dashboard-view">
        <div className="heading-container">
          <div className="heading heading-left">
            <TbDeviceDesktopAnalytics />
            <h1>Data dashboard</h1>
          </div>
          <Button
            className="btn btn-green"
            onClick={downloadDataAsZip}
            startIcon={<TbDownload color="white" />}
          >
            Gegevens Downloaden
          </Button>
        </div>
        <Grid container spacing={3} sx={{ pt: 1, mb: 3 }}>
          <Grid item xs={12} lg={2}>
            <select className="period" value={period} onChange={handlePeriodChange}>
              <option value="monthly">Maand</option>
              <option value="yearly">Jaar</option>
              <option value="all">Altijd</option>
              <option value="custom">Anders</option>
            </select>
          </Grid>
          {period === 'custom' && (
          <>
            <Grid item xs={12} lg={5}>
              <input
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
                className="period-date"
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <input
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
                className="period-date"
              />
            </Grid>
          </>
          )}
          {period !== 'all' && period !== 'custom' && (
          <Grid item xs={12} lg={10}>
            <div className="period-control">
              <Button
                onClick={() => navigateDate(-1)}
                className="btn btn-green"
              >
                <TbArrowLeft />
              </Button>
              <span>{date}</span>
              <Button
                onClick={() => navigateDate(1)}
                className="btn btn-green"
              >
                <TbArrowRight />
              </Button>
            </div>
          </Grid>
          )}
        </Grid>
        <div className="dashboard-container">
          <Masonry columns={2} spacing={6}>
            <Panel
              title="Gebruikers"
              icon={<TbUsersGroup />}
              datasets={[
                {
                  data: activeUsersData,
                  type: 'line',
                  graphTitle: 'Aantal gebruikers',
                  yAxisLabel: 'Aantal gebruikers die matches maken',
                  period,
                  setPeriod,
                  date,
                  setDate,
                  xAxisKey: 'date',
                  yAxisKey: 'count',
                },
                {
                  data: userMatchesData.data,
                  type: 'table',
                  graphTitle: 'Meest actieve gebruikers',
                  period,
                  setPeriod,
                  date,
                  setDate,
                  columns: [
                    { key: 'email', header: 'Gebruiker' },
                    { key: 'count', header: 'Aantal Matches' },
                  ],
                  secondary: (
                    <TextField
                      label="Zoeken..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      sx={{
                        mt: 2, mb: -1, width: '100%', borderRadius: '8px',
                      }}
                      InputProps={{
                        startAdornment: <TbSearch style={{ marginRight: '8px' }} />,
                      }}
                    />
                  ),
                },
              ]}
            />
            <Panel
              title="Klanten"
              icon={<TbUserCircle />}
              datasets={[
                {
                  data: averageAgeData,
                  type: 'line',
                  graphTitle: 'Gemiddelde leeftijd',
                  yAxisLabel: 'Leeftijd',
                  period,
                  setPeriod,
                  date,
                  setDate,
                  xAxisKey: 'date',
                  yAxisKey: 'average_age',
                },
                {
                  data: professionPopularityData,
                  type: 'bar',
                  graphTitle: 'Vaak voorkomende beroepen',
                  yAxisLabel: 'Aantal',
                  period,
                  setPeriod,
                  date,
                  setDate,
                },
              ]}
            />
            <Panel
              title="Cases"
              icon={<TbFolders />}
              datasets={[
                {
                  data: caseStudyPopularityData,
                  type: 'bar',
                  graphTitle: 'Meest gemaakte match',
                  yAxisLabel: 'Aantal',
                  period,
                  setPeriod,
                  date,
                  setDate,
                },
                {
                  data: totalMatchesData,
                  type: 'line',
                  graphTitle: 'Aantal gematchte cases',
                  yAxisLabel: 'Aantal',
                  period,
                  setPeriod,
                  date,
                  setDate,
                  xAxisKey: 'date',
                  yAxisKey: 'count',
                },
              ]}
            />
            <Panel
              title="Overig"
              icon={<TbClipboardData />}
              datasets={[
                {
                  data: pdfDownloadCountData,
                  type: 'line',
                  graphTitle: 'Aantal PDF Downloads',
                  yAxisLabel: 'Downloads',
                  period,
                  setPeriod,
                  date,
                  setDate,
                  xAxisKey: 'date',
                  yAxisKey: 'count',
                },
              ]}
            />
            <Panel
              title="Beroepen"
              icon={<TbBriefcase />}
              datasets={[
                {
                  data: badlyMatchedJobRolesData,
                  type: 'paginated-table',
                  graphTitle: 'Populaire beroepen zonder case',
                  columns: badlyMatchedColumns,
                  fetchPage: fetchBadlyMatchedJobRoles,
                  count: totalCount,
                  pageSize,
                  includeIconColumn: true,
                  iconColumnName: 'Maak Case',
                  onIconClick: handleDashboardIconClick,
                  includeSecondIconColumn: true,
                  secondIconColumnName: 'Verberg',
                  onSecondIconClick: hideProfessionFromBadlyMatched,
                  secondIconColumnClass: 'red',
                  secondIconColumnIcon: <IconEyeOff />,
                },
              ]}
            />
          </Masonry>
        </div>
      </div>
    </Container>
  );
}

export default DataDashboardView;
