import React from 'react';
import { TbFaceId } from 'react-icons/tb';
import { Button, Container, Grid } from '@mui/material';
import { useMatches } from '../../Providers/MatchesProvider/MatchesProvider';
import './BestMatchesView.scss';
import FoundMatch from '../../Components/FoundMatch';

function BestMatchesView() {
  const {
    paginatedMatches, goToNextPage, goToPreviousPage, currentPage, totalPages,
  } = useMatches();

  return (
    <Container maxWidth="lg" sx={{ pt: 6, pb: 9, minHeight: 'calc(100vh - 120px)' }}>
      <div className="heading">
        <TbFaceId />
        <h1>Gevonden risicomatches</h1>
      </div>
      <Grid container spacing={3}>
        {paginatedMatches.map((match, index) => (
          <Grid item xs={12} lg={6} key={index}>
            <FoundMatch match={match} showSoftDelete />
          </Grid>
        ))}
      </Grid>
      {totalPages > 1 && (
        <div style={{ textAlign: 'center' }}>
          <Button
            onClick={goToPreviousPage}
            className={`btn ${currentPage > 1 ? 'btn-green' : 'btn-grey'}`}
            sx={{ mt: 3, mr: 2 }}
          >
            Vorige Pagina
          </Button>
          <Button
            onClick={goToNextPage}
            className={`btn ${currentPage < totalPages ? 'btn-green' : 'btn-grey'}`}
            sx={{ mt: 3 }}
          >
            Volgende Pagina
          </Button>
        </div>
      )}
    </Container>
  );
}

export default BestMatchesView;
