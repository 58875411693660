import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconChevronRight, IconQuestionMark, IconThumbDown, IconThumbUp, IconX,
} from '@tabler/icons-react';
import { Button, Grid, Tooltip } from '@mui/material';
import { CHATBOT_RESPONSES_URL } from '../../../Constants/URLS';
import { UserContext } from '../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../Utils/ApiUtilsHook';
import sourceIcon from '../../../Utils/SourceUtils';

function ChatMessage({
  question, response, expanded, toggleExpandMessage, expandable,
}) {
  const [satisfied, setSatisfied] = useState(response?.user_satisfied || false);
  const [dissatisfied, setDissatisfied] = useState(response?.user_dissatisfied || false);
  const userContext = useContext(UserContext);
  const utils = apiUtilsHook(userContext);
  const [sourcesVisible, setSourcesVisible] = useState(false);

  const handleThumbsUp = async () => {
    setSatisfied(true);
    setDissatisfied(false);
    try {
      await utils.patch(`${CHATBOT_RESPONSES_URL}${response.id}/`, {
        user_satisfied: true,
        user_dissatisfied: false,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating satisfaction:', error);
      setSatisfied(false);
    }
  };

  const handleThumbsDown = async () => {
    setSatisfied(false);
    setDissatisfied(true);
    try {
      await utils.patch(`${CHATBOT_RESPONSES_URL}${response.id}/`, {
        user_satisfied: false,
        user_dissatisfied: true,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating dissatisfaction:', error);
      setDissatisfied(false);
    }
  };

  return (
    <div className="chat-message">
      {/* eslint-disable-next-line */}
      <div
        onClick={expandable ? toggleExpandMessage : null}
        className={`chat-message-question ${expandable ? 'expandable' : ''} ${expanded ? 'expanded' : ''}`}
      >
        {expandable && <IconChevronRight />}
        {question.message}
      </div>
      {response && expanded && (
        <div className="chat-message-response">
          {response?.message}
          <div className="chat-message-controls">
            <Button className={`btn btn-icon ${satisfied ? 'active' : ''}`} onClick={handleThumbsUp} sx={{ mr: 1 }}>
              <IconThumbUp />
            </Button>
            <Button className={`btn btn-icon ${dissatisfied ? 'active' : ''}`} onClick={handleThumbsDown} sx={{ mr: 1 }}>
              <IconThumbDown />
            </Button>
            <Button className="btn btn-icon btn-green" onClick={() => setSourcesVisible(!sourcesVisible)}>
              {sourcesVisible ? <IconX /> : <IconQuestionMark />}
            </Button>
          </div>
          {sourcesVisible && (
            <div className="chat-message-sources">
              <h3>Bronnen waaruit dit antwoord is samengesteld</h3>
              <Grid container spacing={1}>
                {response?.source_details.map((source) => (
                  <Grid item key={source.id} xs={12} lg={2}>
                    <Tooltip title={source.title} placement="top" arrow>
                      {/* eslint-disable-next-line */}
                      <div
                        className="source"
                        // open link in new tab
                        onClick={() => window.open(source.source_file, '_blank')}
                      >
                        <img src={sourceIcon(source)} alt="Source Icon" />
                        {source.title.length > 10 ? `${source.title.substring(0, 10)}...` : source.title}
                      </div>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

ChatMessage.propTypes = {
  // eslint-disable-next-line
  question: PropTypes.object.isRequired,
  // eslint-disable-next-line
  response: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpandMessage: PropTypes.func.isRequired,
  expandable: PropTypes.bool.isRequired,
};

export default ChatMessage;
