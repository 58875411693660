import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Switch, FormControlLabel, Alert,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Swal2 from 'sweetalert2';
import { IconCheck } from '@tabler/icons-react';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { MODERATION_URL } from '../../Constants/URLS';
import './ModerationComponent.scss';

const switchStyles = {
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#BAD60A',
    '&:hover': {
      backgroundColor: 'rgba(186, 214, 10, 0.08)',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#A3C009', // 4% darker than #BAD60A
  },
};

function ModerationComponent({
  modelType, userContext, useFeatured, featured, setFeatured,
}) {
  const { id } = useParams();
  const api = apiUtilsHook(userContext);
  const [moderationData, setModerationData] = useState(null);

  const fetchModeration = async () => {
    try {
      const url = `${MODERATION_URL}?content_type=${modelType}&object_id=${id}`;
      const response = await api.get(url);
      // Assuming the response data is an array, select the first element
      if (response.data.length > 0) {
        setModerationData(response.data[0]);
      } else {
        setModerationData(null); // No matching moderation data found
      }
    } catch (error) {
      Swal2.fire({
        title: 'Fout',
        text: `Kan moderatiestatus niet laden: ${error.response ? error.response.data.message : error.message}`,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    fetchModeration();
  }, [id, modelType]);

  const handleUpdateModeration = async (updateData) => {
    try {
      const response = await api.patch(`${MODERATION_URL}${moderationData.id}/`, updateData);
      if (response.status === 200) {
        setModerationData(response.data);
        Swal2.fire({
          title: 'Succesvol',
          text: 'Moderatiestatus is succesvol bijgewerkt.',
          icon: 'success',
        });
      }
    } catch (error) {
      Swal2.fire({
        title: 'Fout',
        text: `Kan de moderatiestatus niet updaten: ${error.response?.data[0]}`,
        icon: 'error',
      });
    }
  };

  // Return null if moderation data hasn't been loaded
  if (!moderationData) return null;

  return (
    <div className="moderation-container">
      {useFeatured && (
        <FormControlLabel
          control={(
            <Switch
              checked={featured}
              onChange={(e) => setFeatured(e.target.checked)}
              name="featured"
              sx={switchStyles}
            />
          )}
          label="Aanbevolen"
          sx={{ mb: 1, mr: 4 }}
        />
      )}
      <FormControlLabel
        control={(
          <Switch
            checked={moderationData.online}
            onChange={(e) => handleUpdateModeration({ online: e.target.checked })}
            disabled={!(moderationData.first_check_done && moderationData.second_check_done)}
            name="online"
            sx={switchStyles}
          />
        )}
        label="Online"
        sx={{ mb: 1 }}
      />
      {/* eslint-disable-next-line */}
      {moderationData.first_check_done && !moderationData.second_check_done ? (
        <Button
          className="btn btn-grey"
          onClick={() => handleUpdateModeration({ second_check_done: true })}
          startIcon={<IconCheck />}
          sx={{ width: 'fit-content', display: 'flex' }}
        >
          Tweede controle voltooien
        </Button>
      ) : !moderationData.first_check_done ? (
        <Button
          className="btn btn-grey"
          onClick={() => handleUpdateModeration({ first_check_done: true })}
          startIcon={<IconCheck />}
          sx={{ width: 'fit-content', display: 'flex' }}
        >
          Eerste controle voltooien
        </Button>
      ) : (
        <Alert severity="success">
          Alle controles zijn voltooid.
        </Alert>
      )}
    </div>
  );
}

ModerationComponent.propTypes = {
  modelType: PropTypes.oneOf(['source', 'frequentlyaskedquestion', 'topic']).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  userContext: PropTypes.object.isRequired,
  useFeatured: PropTypes.bool,
  featured: PropTypes.bool,
  setFeatured: PropTypes.func,
};

ModerationComponent.defaultProps = {
  useFeatured: false,
  featured: false,
  setFeatured: () => {},
};

export default ModerationComponent;
