import React from 'react';
import PropTypes from 'prop-types';
import { BarChart } from '@mui/x-charts/BarChart';

function BarGraph({
  // eslint-disable-next-line
  data, yAxisDecimalPlaces, barOffset, domainPadding, yAxisLabel, xAxisLabel,
}) {
  const isDataEmpty = data.length === 0;

  // Render a message when there is no data
  if (isDataEmpty) {
    return (
      <div
        style={{
          height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <p>Geen gegevens voor deze periode</p>
      </div>
    );
  }

  return (
    <BarChart
      dataset={data !== undefined ? data : [{}]}
      yAxis={[{ scaleType: 'band', dataKey: 'category' }]}
      series={[{ dataKey: 'value' }]}
      height={300}
      layout="horizontal"
      margin={{ left: 110 }}
    />
  );
}

BarGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  yAxisDecimalPlaces: PropTypes.number,
  barOffset: PropTypes.number,
  domainPadding: PropTypes.number,
  yAxisLabel: PropTypes.string,
  xAxisLabel: PropTypes.string,
};

BarGraph.defaultProps = {
  yAxisDecimalPlaces: 0,
  barOffset: 10,
  domainPadding: 20,
  yAxisLabel: '',
  xAxisLabel: '',
};

export default BarGraph;
