/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import BarGraph from '../BarGraph';
import LineGraph from '../LineGraph';
import ScrollableTable from '../ScrollableTable';
import PaginatedTable from '../PaginatedTable';

function Panel({ title, icon, datasets }) {
  return (
    <div className="dashboard-panel">
      <div className="panel-header">
        {icon}
        <h2>{title}</h2>
      </div>
      <div className="panel-content">
        {datasets.map((dataset, index) => (
          <div key={index}>
            {index > 0 && <hr />}
            <h3>{dataset.graphTitle}</h3>
            {dataset.secondary && <div>{dataset.secondary}</div>}
            {dataset.type === 'bar' ? (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <BarGraph data={dataset.data} {...dataset} />
            ) : dataset.type === 'line' ? (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <LineGraph data={dataset.data} {...dataset} />
            ) : dataset.type === 'paginated-table' ? (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <PaginatedTable
                data={dataset.data}
                columns={dataset.columns}
                fetchPage={dataset.fetchPage}
                count={dataset.count}
                pageSize={dataset.pageSize}
                includeIconColumn={dataset.includeIconColumn ?? false}
                iconColumnName={dataset.iconColumnName ?? ''}
                onIconClick={dataset.onIconClick ?? (() => { })}
                includeSecondIconColumn={dataset.includeSecondIconColumn ?? false}
                secondIconColumnName={dataset.secondIconColumnName ?? ''}
                onSecondIconClick={dataset.onSecondIconClick ?? (() => { })}
                secondIconColumnClass={dataset.secondIconColumnClass ?? ''}
                secondIconColumnIcon={dataset.secondIconColumnIcon ?? ''}
              />
            ) : (
              <ScrollableTable
                data={dataset.data.data}
                columns={dataset.columns}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...dataset}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

Panel.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  datasets: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    type: PropTypes.oneOf(['bar', 'line', 'table', 'paginated-table']).isRequired,
    graphTitle: PropTypes.string.isRequired,
    period: PropTypes.string,
    date: PropTypes.string,
    secondary: PropTypes.node,
  })).isRequired,
};

export default Panel;
