import React from 'react';
import PropTypes from 'prop-types';
import { TbCheck } from 'react-icons/tb';

function RadioCheckboxButton({
  handleSelect, value, selected, fullWidth,
}) {
  return (
    <button
      type="button"
      className={`radio-checkbox-button ${selected ? 'selected' : ''} ${fullWidth ? 'full-width' : ''}`}
      onClick={handleSelect}
    >
      <div className="radio-checkbox-button__indicator">
        {selected && <TbCheck />}
      </div>
      {value}
    </button>
  );
}

RadioCheckboxButton.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};

RadioCheckboxButton.defaultProps = {
  fullWidth: false,
};

export default RadioCheckboxButton;
