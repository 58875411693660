import apiUtilsHook from '../Utils/ApiUtils';
import {
  SIGNUP_URL, JOB_ROLES_URL, VALIDATE_ACCESS_CODE, INVITATION_URL,
} from '../Constants/URLS';

const SignUpApi = (config) => {
  const utils = apiUtilsHook(config);

  return {
    getJobRoles() {
      return utils.get(JOB_ROLES_URL);
    },

    postSignup(signupData) {
      return utils.postNoAuth(SIGNUP_URL, signupData);
    },

    validateAccessCode(email, accessCode) {
      return utils.postNoAuth(VALIDATE_ACCESS_CODE, {
        email,
        access_code: accessCode,
      });
    },

    sendInvitation(email) {
      return utils.postNoAuth(INVITATION_URL, { email });
    },
  };
};

export default SignUpApi;
